angular.module('main')
        .directive('companySelector', function () {
            return {
                scope: {
                    resolve: '<',
                    close: '&',
                    dismiss: '&'
                },
                restrict: 'E',
                templateUrl: 'company-selector.tpl.html'
            };
        });

angular.module('main')
        .directive('companySelector', function () {
            return {
                restrict: 'A',
                controller: controller
            };

            function controller(userService, $uibModal, company, companyService, user) {
                var vm = this;
                if(company.id === '') {
                    loadCompanies();
                }

                function loadCompanies() {
                    userService.getCompanies().then(success, error);

                    function success(response) {
                        if (response.length === 1) {
                          updateCompany(response[0]);
                        } else {
                          vm.companies = response;
                          openModal();
                        }
                    }

                    function error(error) {
                        console.log(error);
                    }
                }

                function openModal() {
                    var modal = $uibModal.open({
                        animation: true,
                        component: 'companySelector',
                        resolve: {
                            companies: function () {
                                return vm.companies;
                            }
                        }
                    });
                    modal.result.then(updateCompany);
                }
                
                function updateCompany(item){
                    company.id = item.id;
                    loadUser();
                    company.name = item.name;
                    company.dni = item.dni;
                    company.description = item.description;
                    company.numberOfEmployees = item.numberOfEmployees;
                    company.classificationBySize = item.classificationBySize;
                    company.sector = item.sector;
                    company.industry = item.industry;
                    company.mainCountry = item.mainCountry;
                    company.countries = item.countries;
                    company.updatedAt = new Date(item.updatedAt);
                    company.availableCertifications = item.availableCertifications;
                    company.logo = item.logo;

                    company.contactPersonName = item.contactPersonName;
                    company.contactPersonEmail = item.contactPersonEmail;
                    company.contactPersonJobPosition = item.contactPersonJobPosition;

                }
                
                function loadUser() {
                    companyService.findUser().then(success, error);

                    function success(response) {
                        user.id = response.id;
                        user.email = response.email;
                        user.fullName = response.fullName;
                        user.jobPosition = response.jobPosition;
                        user.roles = typeof response.roles === 'undefined' ? [] : response.roles;
                    }

                    function error(error) {
                        console.log(error);
                    }
                }
            }
        });