angular.module('main')
        .directive('certificationHeader', function () {
            return {
                scope: {
                    id: '='
                },
                restrict: 'E',
                templateUrl: 'certification-header.tpl.html',
                controller: controller,
                controllerAs: 'vm',
                bindToController: true
            };

            function controller(company, companyCertificationService, companyCertification,routeService) {
                var vm = this;
                vm.company = company;
                vm.loadCompanyCertification = loadCompanyCertification;
                vm.routeApi = routeService.routeLaravel();
                vm.isCal = '';
             
                function loadCompanyCertification() {
                    companyCertificationService.findOneById(vm.id).then(success, error);

                    function success(response) {
                        vm.companyCertification = response;
                        vm.companyCertification.created = new Date(vm.companyCertification.created);
                        vm.companyCertification.updated = new Date(vm.companyCertification.updated);
                       
                        companyCertification.officeId = response.officeId;
                        companyCertification.year = response.year;
                        companyCertification.certification_id = response.certification_id;
                        vm.isCal = response.certification_id;
                    }

                    function error(error) {
                        console.log(error);
                    }
                }
            }
        });