(function () {
    'use strict';

    angular
      .module('main')
      .config(config);

    function config($stateProvider) {
        $stateProvider
        .state('companyConfig', {
            parent: 'main',
            url: '/company',
            templateUrl: 'office.tpl.html',
            controller: 'CompanyConfigController', 
            controllerAs: 'vm'
        });
    }
})();