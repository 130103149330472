(function () {
    'use strict';
    angular
            .module('main')
            .controller('MainController', MainController);

    function MainController($uibModal, company,  $state, user, userService,$window,$scope) {
        var vm = this;
        vm.openMenu = openMenu;
        vm.company = company;
        vm.redirectTo = redirectTo;
        vm.openComponent = openComponent;
        vm.user = user;
        vm.logout = logout;
        vm.hasPermissions = hasPermissions;
        vm.displayMenu = displayMenu;
        var contador = 1;
        vm.close = close;
        var previousUrl = $window.location.href; 
        $scope.$on('$locationChangeSuccess', function(event, newUrl, oldUrl) {
            // Comprobar si se ha producido un retroceso en el navegador
        
            if (previousUrl === newUrl) {
                // Aquí puedes realizar las acciones necesarias cuando se detecta un retroceso             
               const header = document.querySelector('#header-list');                 
               const headerChildren = header.children;

               // Itera a través de los elementos secundarios a partir del segundo elemento
               for (var i = headerChildren.length - 1; i > 0; i--) {
                   // Remueve cada elemento secundario del DOM
                   headerChildren[i].remove();
               }
               headerChildren[0].className = "active";   
              }
              previousUrl = oldUrl
            
          });
        function close() {
            console.log('close');
            $state.reload();
        }
        function openMenu() {
            $uibModal.open({
                animation: true,
                component: 'menu',
                windowClass: 'right'
            });
        }

       

        function hasPermissions(roles)
        {
            return userService.hasPermissions(user, roles);
        }
        function displayMenu()
        {
          const nav =   document.getElementById('content-nav');
          const img =   document.getElementById('img-menu-mobile');
          if ( nav.style.display == 'block') {
            nav.style.display = 'none';
            img.src = '../../img/content/menu.png';
          }else{
            nav.style.display = 'block';
            img.src = '../../img/content/exit-white.png';
          }
        }

        function redirectTo(route) {
            $state.go(route);
        }

        function openComponent(name) {
            $uibModal.open({
                animation: true,
                component: name,
                windowClass: 'right'
            });
        }

        function logout() {
            userService.logout();
        }

        
    }


})();