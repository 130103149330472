angular.module('main')
        .directive('deleteProductionLine', function () {
            return {
                scope: {
                    resolve: '<',
                    close: '&',
                    dismiss: '&'
                },
                restrict: 'E',
                templateUrl: 'delete-production-line.tpl.html',
                controller: controller
            };

            function controller($scope, officeService, $state) {
                init();

                function init() {
                    $scope.model = {};
                    $scope.office = $scope.resolve.office;
                    $scope.productionLine = $scope.resolve.productionLine;
                    $scope.delete = deleteProductionLine;
                    $scope.key = $scope.resolve.key;
                    
                }

                function deleteProductionLine(officeId, productionLineId)
                {
                    var promise = officeService.deleteProductionLine(officeId, productionLineId);
                    promise.then(function(data){
                        $scope.resolve.reloadList();
                        $scope.close();
                    }, function (error) {
                    });
                }
            }
        });