(function () {
    'use strict';

    angular
        .module('main')
        .factory('company', company);

    function company() {

        return { 
            id: '',
            name: ''
        };
    }
})();