angular.module('main')
        .directive('indicatorFilterBuilder', function () {
            return {
                scope: {
                    onSave: '='
                },
                restrict: 'E',
                templateUrl: 'indicator_filter_builder.tpl.html',
                controller: controller
            };

            function controller($scope, certificationService, $stateParams, industryService, countryService, materialAspectService, indicatorService) {
                $scope.filterTypes = [
                    'ALL',
                    'SUB_CERTIFICATION',
                    'INDUSTRY',
                    'COUNTRY',
                    'MATERIAL_ASPECT'
                ];
                $scope.model = {};
                $scope.filters = [];
                $scope.filterTypeChange = filterTypeChange;
                $scope.subCertificationChange = subCertificationChange;
                $scope.industryChange = industryChange;
                $scope.countryChange = countryChange;
                $scope.materialAspectChange = materialAspectChange;
                $scope.loadIndicators = loadIndicators;
                $scope.addIndicator = addIndicator;
                $scope.removeIndicator = removeIndicator;
                $scope.removeSavedFilters = removeSavedFilters;
                loadSavedFilters();

                function filterTypeChange() {
                    resetFormModel();
                    switch ($scope.filterType) {
                        case 'ALL':
                            $scope.showFlag = true;
                            break;
                        case 'SUB_CERTIFICATION':
                            loadSubCertifications();
                            break;
                        case 'INDUSTRY':
                            loadIndustries();
                            break;
                        case 'COUNTRY':
                            loadCountries();
                            break;
                        case 'MATERIAL_ASPECT':
                            loadMaterialAspects();
                            break;
                    }

                    function resetFormModel() {
                        $scope.industries = [];
                        $scope.model.industry = null;

                        $scope.model.country = null;
                        $scope.countries = [];

                        $scope.materialAspects = [];
                        $scope.model.materialAspect = null;

                        $scope.showFlag = false;
                        $scope.model.flag = null;
                    }
                }

                function loadSubCertifications() {
                    certificationService.findSubCertifications($stateParams.id)
                            .then(success)
                            .catch(error);

                    function success(response) {
                        $scope.subCertifications = response;
                    }

                    function error(error) {
                        console.log(error);
                    }
                }

                function subCertificationChange() {
                    $scope.showFlag = true;
                }

                function loadIndustries() {
                    industryService.findAll()
                            .then(success)
                            .catch(error);

                    function success(response) {
                        $scope.industries = response;
                    }

                    function error(error) {
                        console.log(error);
                    }
                }

                function industryChange(industry) {
                    industryService.findSubIndustries(industry.id)
                            .then(success)
                            .catch(error);

                    function success(response) {
                        if (response.length > 0) {
                            industry.subIndustries = response;
                            return;
                        }

                        $scope.model.industry = industry;
                        $scope.showFlag = true;
                    }

                    function error(error) {
                        console.log(error);
                    }
                }

                function loadCountries() {
                    countryService.findAll()
                            .then(success)
                            .catch(error);

                    function success(response) {
                        $scope.countries = response;
                    }

                    function error(error) {
                        console.log(error);
                    }
                }

                function countryChange() {
                    $scope.showFlag = true;
                }

                function loadMaterialAspects() {
                    materialAspectService.findAll()
                            .then(success)
                            .catch(error);

                    function success(response) {
                        $scope.materialAspects = response;
                    }

                    function error(error) {
                        console.log(error);
                    }
                }

                function materialAspectChange(materialAspect) {
                    materialAspectService.findSubMaterialAspects(materialAspect.id)
                            .then(success)
                            .catch(error);

                    function success(response) {
                        if (response.length > 0) {
                            materialAspect.subMaterialAspects = response;
                            return;
                        }

                        $scope.model.materialAspect = materialAspect;
                        $scope.showFlag = true;
                    }

                    function error(error) {
                        console.log(error);
                    }
                }

                function loadIndicators(searchText) {
                    indicatorService.searchByText(searchText)
                            .then(success)
                            .catch(error);

                    function success(response) {
                        $scope.indicators = response;
                    }

                    function error(error) {
                        console.log(error);
                    }
                }

                function addIndicator(index) {
                    $scope.model.indicator = {
                        id: $scope.indicators[index].id,
                        code: $scope.indicators[index].code,
                        name: $scope.indicators[index].name
                    };
                    $scope.model.certification = {id: $stateParams.id};
                    $scope.filters.push(angular.copy($scope.model));
                    $scope.model.indicator = {};
                }

                function removeIndicator(index) {
                    $scope.filters.splice(index, 1);
                }

                function loadSavedFilters() {
                    certificationService.findIndicatorFilters($stateParams.id)
                            .then(success)
                            .catch(error);

                    function success(response) {
                        $scope.savedFilters = response;
                    }

                    function error(error) {
                        console.log(error);
                    }
                }
                
                function removeSavedFilters(index){
                    certificationService.removeIndicatorFilter($stateParams.id, $scope.savedFilters[index].id);
                    $scope.savedFilters.splice(index, 1);
                }
            }
        });