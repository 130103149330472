(function () {
    'use strict';

    angular
      .module('main')
      .config(config);

    function config($stateProvider) {
        $stateProvider
        .state('officesConfig', {
            parent: 'main',
            url: '/offices/:id/config/:year',
            templateUrl: 'office-config.tpl.html',
            controller: 'OfficeConfigController', 
            controllerAs: 'vm'
        });
    }
})();