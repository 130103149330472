angular.module('main')
        .directive('multipleSelectionIndicator', function () {
            return {
                scope: {
                    indicator: '=',
                    container: '=',
                    onClick: '&'
                },
                transclude: true,
                replace: true,
                restrict: 'E',
                templateUrl: 'multiple-selection-indicator.tpl.html',
                controller: controller,
            };

            function controller($scope, $sce) {
                var inputText = $scope.indicator.help;
                if(typeof inputText === 'string' || inputText instanceof String){
                    $scope.indicator.help = $sce.trustAsHtml($scope.indicator.help);  
                }
                $scope.uploadProperties = {
                    type: 'evidence',
                    accept: '*/*',
                    preview: false,
                    multiple: true
                };
                $scope.selectOption = selectOption;

                if (typeof $scope.indicator.answer === 'undefined') {
                    $scope.indicator.answer = { evidences: [], options: [], enabledByUser: true };
                }
                
                if (typeof $scope.indicator.answer.options === 'undefined') {
                    $scope.indicator.answer.options = $scope.indicator.answerOptions.reduce(reduceOptions, []);
                }

                updateCompletedYearlyIndicators();
                setIsChecked();

                function updateCompletedYearlyIndicators() {
                    if (!$scope.indicator.requiredQuestion || !$scope.container) {
                        return;
                    }

                    if (!$scope.indicator.answer.enabledByUser) {
                        return;
                    }

                    $scope.container.requiredYearlyIndicators++;

                    var checkedOption = $scope.indicator.answer.options.find(function(option) {
                        return option.checked;
                    });

                    if (checkedOption) {
                        $scope.container.completedYearlyIndicators += 1;
                    }
                }

                function reduceOptions(result, item) { 
                    return result.concat(item.subOptions.map(mapSubOptions));
                    
                    function mapSubOptions(subItem) {
                        delete subItem.subOptions;
                        return {
                            parentOption: item.name,
                            option: subItem,
                            checked: false
                        };
                    }
                }

                function setIsChecked() {
                    var answerOptions = $scope.indicator.answerOptions;
                    var selectedOptions = $scope.indicator.answer.options;
                    if (answerOptions.length > 0) {
                        answerOptions.forEach(function(option) {
                            var subOptions = option.subOptions;
                            if (subOptions.length > 0) {
                                subOptions.forEach(function(subOption) {
                                    var isSelected = selectedOptions.find(function (selectedOption) {
                                        return (
                                            selectedOption.option.id === subOption.id &&
                                            selectedOption.checked
                                        );
                                    });
                                    subOption.checked = !!isSelected;
                                })
                            }
                        })
                    }
                }

                function selectOption(parentOption, selectedOption) {
                    if (selectedOption && selectedOption.checked) {
                        $scope.indicator.answer.options.push({
                            checked: selectedOption.checked,
                            option: {
                                id: selectedOption.id,
                                name: selectedOption.name,
                                subOptions: selectedOption.subOptions,
                            },
                            parentOption: parentOption,
                        });
                    } else {
                        $scope.indicator.answer.options.forEach(function (el) {
                            if (el.option.id === selectedOption.id) {
                                el.checked = false;
                            }
                        });
                    }
                }
            }
        });