angular.module('main')
        .directive('monthlyGroupIndicator', function () {
            return {
                scope: {
                    indicator: '=',
                    container: '=',
                    onClick: '&'
                },
                restrict: 'E',
                templateUrl: 'monthly-group-indicator.tpl.html',
                controller: controller
            };

            
            function controller($location, $anchorScroll, $scope, $sce, $filter, answerService,$uibModal) {
                var officeConfigurationCodes = [];
                $scope.showGraphGroup = false;
                $scope.estadoDiv = estadoDiv;
                var inputText = $scope.indicator.help;
                if(typeof inputText === 'string' || inputText instanceof String){
                    $scope.indicator.help = $sce.trustAsHtml($scope.indicator.help);  
                }
                $scope.updateEnabledByUser = updateEnabledByUser;
                $scope.monedaSucursal = monedaSucursal;
                $scope.uploadProperties = {
                    type: 'evidence',
                    accept: '*/*',
                    preview: false,
                    multiple: true
                };
                        
                if (typeof $scope.indicator.answer === 'undefined') {
                    $scope.indicator.answer = { evidences: [], subAnswers:[], enabledByUser: true };
                }
         
                 $scope.$watch('indicator.answer.subAnswers', function () {
                    $scope.indicator.formulas.forEach(function(formula) {
                        var subAnswers = $scope.indicator.answer.subAnswers;
                        var costs = $scope.indicator.answer.costs;
                        var officeConfigurations = $scope.indicator.answer.officeConfigurations;
                        officeConfigurationCodes = getConfigurationCodes(officeConfigurations);
                       
                       
                     applyFormula(subAnswers, costs, formula, officeConfigurations,'january');
                     applyFormula(subAnswers, costs, formula, officeConfigurations,'february');
                     applyFormula(subAnswers, costs, formula, officeConfigurations,'march');
                     applyFormula(subAnswers, costs, formula, officeConfigurations,'april');
                     applyFormula(subAnswers, costs, formula, officeConfigurations,'may');
                     applyFormula(subAnswers, costs, formula, officeConfigurations,'june');
                       applyFormula(subAnswers, costs, formula, officeConfigurations,'july');
                       applyFormula(subAnswers, costs, formula, officeConfigurations,'august');
                       applyFormula(subAnswers, costs, formula, officeConfigurations,'september');
                       applyFormula(subAnswers, costs, formula, officeConfigurations,'october');
                       applyFormula(subAnswers, costs, formula, officeConfigurations,'november');
                       applyFormula(subAnswers, costs, formula, officeConfigurations,'december');
                    
                        applyFormula(subAnswers, costs, formula, officeConfigurations);
                    });
                  
              
                }, true); 
                updateCompletedMonthlyIndicators();

            
              

                 function getConfigurationValueForMonth(officeConfigurations, code, key) {
                    var configValue = 0;
                    if (officeConfigurations && officeConfigurations.length > 0) {
                        var configuration;
                        for (var i = 0; i < officeConfigurations.length; i++) {
                            configuration = officeConfigurations[i];
                            if (configuration.group && configuration.group.code === code) {
                                if (configuration[key]) {
                                    configValue = configuration[key];
                                    break;
                                }
                            }
                        }
                    }
                    return configValue;
                }

                function getConfigurationCodes(officeConfigurations) {
                    var codes = [];
                    if (officeConfigurations && officeConfigurations.length > 0) {
                        officeConfigurations.forEach(function(configuration) {
                            if (configuration.group && configuration.group.code) {
                                codes.push(configuration.group.code);
                            }
                        });
                    }
                    return codes;
                }
                
                function applyFormula(subAnswers, costs, formula, officeConfigurations, key) {
                    var operations = formula.operations.replace(/,/g, ' ');
                    var lines = formula.isForCost ? costs : subAnswers;
                    
                    for(var i=0; i<lines.length; i++) {
                        var value = 0;
                        if(typeof key !== 'undefined') {
                            // Use the subanswer values when the costs row is disabled.
                            value = 
                            formula.isForCost && !lines[i].indicator.allowCostsRow
                                ? parseFloat(subAnswers[i][key]) || 0
                                : parseFloat(lines[i][key]) || 0;
                        } else {
                            value = 
                            formula.isForCost && !lines[i].indicator.allowCostsRow
                                ? $filter('sum')(subAnswers[i])
                                : $filter('sum')(lines[i]);
                        }
                        
                        var re = new RegExp(lines[i].indicator.code,'g');
                        operations =  operations.replace(re, value);

                        // If the formula contains any office configuration, this replaces it with the
                        // corresponding value of the given month (key).
                        var forOffice = false;
                        if (officeConfigurationCodes && officeConfigurationCodes.length > 0 && key) {
                            forOffice =true;
                            officeConfigurationCodes.forEach(function(configuration) {
                                if (operations.includes(configuration)) {
                                    var configValue = getConfigurationValueForMonth(
                                        officeConfigurations, configuration, key
                                    );
                                    operations =  operations.replace(configuration, configValue);
                                }
                            });
                        }
                    }
                    
                    var result = 0;
                    
                    try {
                        result = eval(operations);
                    } catch (e) {
                        console.log(e);
                    }
                    
                    if(!isFinite(result)) {
                        result = 0;
                    }
                    
                    result = isNaN(result) ? 0 : result;
                    result = result % 1 !== 0 ? result.toFixed(2) : result;
                    if (key) {

                    formula[key] = result + (formula.symbol ? formula.symbol : '') ;
                       
                    } else {
                       formula.result = result + (formula.symbol ? formula.symbol : '') ;
                    }
                   // result + (formula.symbol ? formula.symbol : '');
                    return     result + (formula.symbol ? formula.symbol : '') ;
                }

                // function updateCompletedMonthlyIndicators() {
                //     if (!$scope.indicator.requiredQuestion) {
                //         return;
                //     }
                //
                //     if (!$scope.indicator.answer.enabledByUser) {
                //         return;
                //     }
                //
                //     $scope.container.requiredMonthlyIndicators++;
                //
                //     console.log("requiredMontlyIndicators:" + $scope.container.requiredMonthlyIndicators);
                //     var completed = true;
                //     for (var index = 0; index < $scope.indicator.answer.subAnswers.length; index++) {
                //         var answer = $scope.indicator.answer.subAnswers[index];
                //         console.log("WHAT");
                //         console.log($scope.indicator.answer);
                //         console.log($scope.indicator.answer.subAnswers[index]);
                //         console.log("WHAT");
                //         if (answer.indicator.type === 'MONTHLY_FORM') {
                //             continue;
                //         }
                //
                //         completed = answerService.isMonthlyCompleted(answer);
                //         if (!completed) {
                //             break;
                //         }
                //     }
                //
                //     if (completed) {
                //         $scope.container.completedMonthlyIndicators += 1;
                //     }
                // }

                function updateCompletedMonthlyIndicators() {
                    if (!$scope.indicator.requiredQuestion) {
                        return;
                    }

                    if (!$scope.indicator.answer.enabledByUser) {
                        return;
                    }

                    $scope.container.requiredMonthlyIndicators += 12;
                    $scope.container.totalMonthlyIndicators += 12;

                    // $scope.container.requiredMonthlyIndicators++;

                    //console.log("requiredMontlyIndicators:" + $scope.container.requiredMonthlyIndicators);
                    var completed = true;
                    for (var index = 0; index < $scope.indicator.answer.subAnswers.length; index++) {
                        var answer = $scope.indicator.answer.subAnswers[index];
                        /*console.log("WHAT");
                        console.log($scope.indicator.answer);
                        console.log($scope.indicator.answer.subAnswers[index]);
                        console.log("WHAT");*/
                        // if (answer.indicator.type === 'MONTHLY_FORM') {
                        //     console.log('INDICADOR MENSUAL');
                        //     console.log(answer);
                        //     console.log('INDICADOR MENSUAL');
                        // }

                                if (answer.indicator.type === 'MONTHLY_FORM') {
                                    continue;
                                }

                        // completed = answerService.isMonthlyCompleted(line);
                        completed = answerService.quantityMonthly(answer);
                                /*console.log("CANTIDAD MONTHLY GROUP");
                                console.log(completed);
                                console.log("CANTIDAD MONTHLY GROUP");*/
                        if (!completed) {
                            break;
                        }
                    }

                    if (completed) {
                        $scope.container.completedMonthlyIndicators += 1;
                        /*console.log('completo');
                        console.log($scope.container.completedMonthlyIndicators)
                        console.log('completo');*/
                    }
                }

                function updateEnabledByUser() {
                    $location.hash('ci' + $scope.indicator.id);
                    $anchorScroll();
                }
                function estadoDiv() {
                    $scope.indicator.showGraphGroup = false;
                    document.getElementById('chart-'+$scope.indicator.id).style.display = 'none';
                    
                }

                function monedaSucursal() {
                    var officeConfigurations = $scope.indicator.answer.officeConfigurations;
                    var codes = '';
                    if (officeConfigurations && officeConfigurations.length > 0) {
                        var configuration = officeConfigurations[0];
                            if (configuration.office.currency && configuration.office.currency.code) {
                                codes=(configuration.office.currency.code);  
                            }
                    }
                    return codes;
                
                }
            }
        });
