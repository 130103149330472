angular.module('main')
        .directive('toggleConfigurationOptionAutocomplete', function () {
            return {
                scope: {
                    resolve: '<',
                    close: '&',
                    dismiss: '&'
                },
                restrict: 'E',
                templateUrl: 'toggle-configuration-option-autocomplete.tpl.html',
                controller: controller
            };

            function controller($scope, officeService) {
                init();

                function init() {
                    $scope.office = $scope.resolve.office;
                    $scope.configurationOption = $scope.resolve.configurationOption;
                    $scope.type = $scope.resolve.type;
                    $scope.toggleTooltipText = $scope.resolve.toggleTooltipText;
                    $scope.acceptToggleAutocomplete = acceptToggleAutocomplete;
                    $scope.cancelToggleAutocomplete = cancelToggleAutocomplete;

                    $("#toggle-message").text(
                        $scope.toggleTooltipText(
                          !$scope.configurationOption.autocompleteNextMonths
                        )
                    );
                }

                function acceptToggleAutocomplete() {
                    var promise;

                    if ($scope.type) {
                        promise = officeService
                            .toggleOptionAutocompleteByType($scope.type, $scope.configurationOption)
                            .then(success, error);
                    } else {
                        promise = officeService
                            .toggleConfigurationOptionAutocomplete($scope.configurationOption)
                            .then(success, error);
                    }

                    function success(response) {
                        $scope.close();
                    }
        
                    function error(error) {
                        console.log(error)
                    }
                }

                function cancelToggleAutocomplete() {
                    $scope.configurationOption.autocompleteNextMonths = !$scope.configurationOption.autocompleteNextMonths;
                    $scope.close();
                }
            }
        });