angular.module('main')
        .directive('deleteCertificationSucursal', function () {
            return {
                scope: {
                    resolve: '<',
                    close: '&',
                    dismiss: '&'
                },
                restrict: 'E',
                templateUrl: 'delete-certification-sucursal.tpl.html',
                controller: controller
            };

            function controller($scope, companyCertificationService, $state) {
                init();

                function init() {
                    $scope.model = {};
                    $scope.certification = $scope.resolve.certification;
                    $scope.delete = deleteCertification;
                    $scope.key = $scope.resolve.key;
                    
                }

                function deleteCertification(id)
                {
                    var promise = companyCertificationService.deleteCertification(id);
                    promise.then(function(data){
                        $scope.resolve.loadCertifications();
                        $scope.close();
                    }, function (error) {
                    });
                }
            }
        });