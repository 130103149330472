angular.module('main')
        .directive('message', function () {
            return {
                scope: {
                    type: '=',
                    text: '='
                },
                replace: true,
                restrict: 'E',
                templateUrl: 'message.tpl.html',
                controller: controller,
                controllerAs: 'vm',
                bindToController: true
            };

            function controller() {
                var vm = this;
                vm.messages = [];
                vm.closeMessage = closeMessage;
                vm.init = init;
                
                function init() {
                    vm.messages.push({
                        type: vm.type,
                        text: vm.text
                    });
                }
                
                function closeMessage(index) {
                    vm.messages.splice(index, 1);
                }
            }
        });