angular.module('main')
        .directive('containerColor', function () {
            return {
                restrict: 'A',
                scope: {
                    container: '=',
                    filter: '=',
                },
                link: function (scope, element) {
                    /*scope.$watch('container.monthlyIndicatorsProgres', function() {
                        updateClass();
                    });
                    scope.$watch('container.yearlyIndicatorsProgres', function() {
                        updateClass();
                    });*/
                    scope.$watch('filter', function() {
                        updateClass();
                    });

                    function updateClass() {
                        var progress = 0;
                        if (scope.filter === 'monthly') {
                            progress = scope.container.monthlyProgress /100;
                        }
        
                        if (scope.filter === 'yearly') {
                            progress = scope.container.annualProgress / 100;
                        }


                        if (progress < 0.25) {
                            angular.element(element).addClass('red');
                            angular.element(element).removeClass('turquesa');
                        }

                        if (progress > 0.25 && progress < 1) {
                            console.log(scope.container.name);
                            console.log(scope.filter);
                            console.log(progress);
                            angular.element(element).addClass('orange');
                            angular.element(element).removeClass('turquesa');
                        }

                        if (progress >= 1) {
                            angular.element(element).removeClass('red');
                            angular.element(element).removeClass('orange');
                            angular.element(element).addClass('turquesa');
                        }
                    }
                }
            };
        });
