(function () {
    'use strict';

    angular
            .module('main')
            .service('howDidYouFindUsOptionService', howDidYouFindUsOptionService);

    function howDidYouFindUsOptionService($q, $http) {

        return {
            findAll: findAll
        };

        function findAll() {
            var deferred = $q.defer();

            $http.get('/api/how-did-you-find-us-options', {
                headers: {skipAuthorization: true}
            }).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
    }
})();