angular.module('main')
        .filter('sum', function () {
            return sum;

            function sum(array, property) {
                if(typeof array === 'undefined'){
                    return 0;
                }
                
                if (typeof array.length === 'undefined') {
                    return sumObject(array);
                }
                
                if(typeof property !== 'undefined') {
                    return sumByProperty(array, property);
                }
                
                var total = 0;
                for (var i = 0; i < array.length; i++) {
                    total += parseFloat(array[i]) || 0;
                }
                return total;
            }

            function sumByProperty(item, property) {
                var total = 0;
                for(var i=0; i<item.length; i++) {
                    total += !isNaN(item[i][property]) ? parseFloat(item[i][property]) : 0;
                }
                return total;
            }

            function sumObject(object) {
                var total = 0;
                for (var k in object) {
                    if (k !== 'year' && k !== 'id' && k !== 'indicator' && k !== 'certification' && k !== 'unit') {
                        total += parseFloat(object[k]) || 0;
                    }
                }
                return total;
            }
        });