(function () {
    'use strict';

    angular
      .module('main')
      .config(config);

    function config($stateProvider) {
        $stateProvider
        .state('certification', {
            parent: 'main',
            url: '/certification/:id',
            templateUrl: 'certification.tpl.html',
            controller: 'CertificationController', 
            controllerAs: 'vm'
        });
    }
})();