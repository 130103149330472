angular.module('main')
        .directive('newProductionLine', function () {
            return {
                scope: {
                    resolve: '<',
                    close: '&',
                    dismiss: '&'
                },
                restrict: 'E',
                templateUrl: 'start-certification.tpl.html',
                controller: controller
            };

            function controller($scope, unitOfMeasureService) {
                $scope.model = {};
                loadUnitOfMeasures();

                function loadUnitOfMeasures() {
                    unitOfMeasureService.findAll()
                            .then(success)
                            .catch(error);

                    function success(response) {
                        $scope.unitOfMeasures = response;
                    }

                    function error(error) {
                        console.log(error);
                    }
                }
            }
        });