(function () {
    'use strict';

    angular
            .module('main')
            .config(config);

    function config($stateProvider) {
        $stateProvider.state('certificationWizard', {
            parent: 'main',
            url: '/certification-wizard/:id',
            templateUrl: 'certification-wizard.tpl.html',
            controller: 'CertificationWizardController',
            controllerAs: 'vm'
        });
    }
})();