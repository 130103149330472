angular.module('main')
    .directive('calculatorGeneralInfo', function() {
        return {
            scope: {
                indicator: '=',
                id: '=',
                container: '=',
            },
            restrict: 'E',
            templateUrl: 'calculator-general-info.tpl.html',
            controller: controller,
            controllerAs: 'vm',
            bindToController: true
        };

        function controller(company,companyCertificationService) {
            var vm = this;
             vm.save = save;
           
           
            vm.modelCompany = angular.copy(company);
            if (typeof vm.indicator.answers === 'undefined') {
                loadAnswers();
            }else{
                loadAnswers();
            }


            function loadAnswers() {
                companyCertificationService.loadIndicatorAnswersCalculator(vm.id, vm.indicator.id,'information')
                .then(success)
                .catch(error);
                function success(response) {
                  vm.indicator.answer = response.data;
                }

                function error(error) {
                    console.log(error);
                }  
            }

            function save() {
                vm.indicator.answer.indicator_id = vm.indicator.id;
                vm.indicator.answer.company_certification_id = vm.id;
                companyCertificationService.saveIndicatorAnswersCalculator( vm.indicator.answer,'information')
                .then(success)
                .catch(error);
                function success(response) {
                  vm.indicator.answer = response.data;
                }

                function error(error) {
                    console.log(error);
                }               
            }

        }
    });