angular.module("main")
    .directive("officeConfigurationContainerTemplate", function () {
        return {
            scope: {
                title: "=",
                office: "=",
                year: "=",
                configurationOptionValues: "=",
            },
            restrict: "E",
            replace: true,
            templateUrl: "office-configuration-container-template.tpl.html",
            controller: controller,
            controllerAs: "vm",
            bindToController: true,
        };

        function controller($uibModal, officeService) {
            var vm = this;
            vm.saveOfficeConfigurationOption = saveOfficeConfigurationOption;
            vm.toggleUpdateNextMonths = toggleUpdateNextMonths;
            vm.toggleTooltipText = toggleTooltipText;
            vm.monthValueChange = monthValueChange;

            function saveOfficeConfigurationOption() {
                officeService.saveOfficeConfigurationOption(
                        vm.office,
                        vm.configurationOptionValues
                    )
                    .catch(error);

                function error(error) {
                    console.log(error);
                }
            }

            function monthValueChange(month) {
                if (vm.configurationOptionValues.autocompleteNextMonths) {
                    var months = [
                        "january",
                        "february",
                        "march",
                        "april",
                        "may",
                        "june",
                        "july",
                        "august",
                        "september",
                        "october",
                        "november",
                        "december",
                    ];
                    
                    var nextMonthPosition = months.indexOf(month) + 1;
    
                    for (var pos = nextMonthPosition; pos < months.length; pos++) {
                        var currentMonth = months[pos];
                        vm.configurationOptionValues[currentMonth] = vm.configurationOptionValues[month];
                    }
                }
            }

            function toggleUpdateNextMonths() {
                $uibModal.open({
                    animation: true,
                    backdrop: 'static',
                    component: 'toggleConfigurationOptionAutocomplete',
                    resolve: {
                        office: function () {
                            return vm.office.id;
                        },
                        configurationOption: function () {
                            return vm.configurationOptionValues;
                        },
                        toggleTooltipText: function () {
                            return toggleTooltipText;
                        }
                    }
                });
            }

            function toggleTooltipText(flag){
                if(flag){
                    return "Al apagarlo, si modifica el valor para un mes específico, los siguientes meses no se actualizarán con ese mismo valor.";
                }else{
                    return "Al encender esta opción, si modifica el valor para un mes específico, los siguientes meses se actualizarán con ese mismo valor.";
                }
            }
        }
    });
