(function () {
    'use strict';
    angular
            .module('main')
            .controller('InventoryController', InventoryController);

    function InventoryController(companyService, company, companyCertificationService, companyCertification) {
        var vm = this;
        vm.load = load;
        vm.company = company;
        vm.loadInventary = loadInventary;

        function load() {
            companyService.findCompanyCertifications()
                    .then(success)
                    .catch(error);

            function success(response) {
                vm.companyCertifications = response;
            }

            function error(error) {
                console.log(error);
            }
        }

        function loadInventary(item) {
            companyCertificationService.loadFormIndicators(item.id, 'inventory')
                    .then(success)
                    .catch(error);

            function success(response) {
                companyCertification.id = item.id;
                vm.indicators = response;
            }

            function error(error) {
                console.log(error);
            }
        }
    }

})();