angular.module('main')
        .directive('formGroup', function () {
            return {
                scope: {
                    form: '=',
                    input: '='
                },
                restrict: 'EA',
                transclude: true,
                replace: true,
                templateUrl: 'form-group.tpl.html'
            };
        });