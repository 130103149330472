(function () {
    'use strict';

    angular
            .module('main')
            .service('countryService', countryService);

    function countryService($q, $http) {

        return {
            findAll: findAll,
            findAllC : findAllC
        };

        function findAll() {
            var deferred = $q.defer();

            $http.get('/api/countries', {
                headers: {skipAuthorization: true}
            }).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
        function findAllC(code) {
            var deferred = $q.defer();

            $http.get('/api/countriesC/'+code, {
                headers: {skipAuthorization: true}
            }).then(success, error);

            function success(response) {
               
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
    }
})();