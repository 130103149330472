(function () {
    'use strict';

    angular
            .module('main')
            .controller('CertificationWizardController', CertificationWizardController);

    function CertificationWizardController($scope, $stateParams, companyCertificationService, companyCertification, $state, materialAspectService, containerService) {

        var vm = this;
        vm.step = 1;
        companyCertification.id = $stateParams.id;
        vm.nextStep = nextStep;

        loadContainers();
        loadCompanyCertification();

        $scope.next = next;


        function loadCompanyCertification() {
            companyCertificationService.findOneById($stateParams.id).then(success, error);

            function success(response) {
                vm.companyCertification = response;
                loadMaterialAspects();
            }

            function error(error) {
                console.log(error);
            }
        }

        function next() {
            $state.go('certification', {id: $stateParams.id});
        }

        function loadContainers() {
            companyCertificationService.loadSetupContainers($stateParams.id).then(success, error);

            function success(response) {
                if (response.length < 1) {
                    next();
                    return;
                }

                $scope.containers = response;

                for (var i = 0; i < $scope.containers.length; i++) {
                    loadSubContainers($scope.containers[i]);
                }
            }

            function error(error) {
                console.log(error);
            }
        }

        function loadSubContainers(container) {
            containerService.findSubContainers(container.id).then(success, error);

            function success(response) {
                if (response.length === 0) {
                    return;
                }
                container.subContainers = response;
                for (var i = 0; i < container.subContainers.length; i++) {
                    loadSubContainers(container.subContainers[i]);
                }
            }

            function error(error) {
                console.log(error);
            }
        }

        function nextStep() {
            if (vm.step >= 2 || vm.materialAspects.length === 0) {
                next();
            }
            vm.step++;
        }

        function loadMaterialAspects() {
            materialAspectService.findAll()
                    .then(success)
                    .catch(error);

            function success(response) {
                vm.materialAspects = response;
                findSuggestedMaterialAspects();
            }

            function error(error) {
                console.log(error);
            }
        }

        function findSuggestedMaterialAspects() {
            companyCertificationService.findSuggestedMaterialAspects(vm.companyCertification.id)
                    .then(success)
                    .catch(error);

            var options = [];
            function success(response) {
                options = response.reduce(flatOptions, []);
                vm.suggestions = response;
                vm.editMode = vm.suggestions.length > 0 ? false : true;
                vm.materialAspects.map(mapOptions);
            }

            function error(error) {
                console.log(error);
            }

            function flatOptions(result, item) {
                return result.concat(item.subMaterialAspects);
            }

            function mapOptions(item) {
                if (item.subMaterialAspects.length > 0) {
                    item.subMaterialAspects.map(mapOptions);
                } else {
                    var result = options.filter(filter);
                    item.checked = result.length > 0 ? true : false;
                }
                return item;

                function filter(temp) {
                    if (temp.id === item.id) {
                        return item;
                    }
                }
            }
        }
    }

})();