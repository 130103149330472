(function () {
    'use strict';

    angular
      .module('main')
      .config(config);

    function config($stateProvider) {
        $stateProvider
        .state('login', {
            parent: 'singlePage',
            url: '/login',
            templateUrl: 'login.tpl.html',
            controller: 'LoginController', 
            controllerAs: 'vm'
        });
    }
})();