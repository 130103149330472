(function () {
    'use strict';

    angular
      .module('main')
      .config(config);

    function config($stateProvider) {
        $stateProvider
        .state('configuration', {
            parent: 'inner',
            url: '/config-company',
            templateUrl: 'config-company.tpl.html',
            controller: 'ConfigurationController', 
            controllerAs: 'vm'
        });
        $stateProvider
        .state('configuration-branch', {
            parent: 'inner',
            url: '/config-branch',
            templateUrl: 'config-branch.tpl.html',
            controller: 'ConfigurationController', 
            controllerAs: 'vm'
        });
        $stateProvider
        .state('configuration-inventory', {
            parent: 'inner',
            url: '/config-inventory',
            templateUrl: 'config-inventory.tpl.html',
            controller: 'ConfigurationController', 
            controllerAs: 'vm'
        });
    }
})();