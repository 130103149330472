(function () {
    'use strict';

    angular
      .module('main')
      .config(config);

    function config($stateProvider) {
        $stateProvider
        .state('help', {
            parent: 'inner',
            url: '/help',
            templateUrl: 'help.tpl.html',
            controller: 'HelpController', 
            controllerAs: 'vm'
        });
    }
})();