angular.module('main')
    .directive('monthlyFormIndicator', function () {
        return {
            scope: {
                indicator: '=',
                container: '=',
                onClick: '&',
                companyCertification: '='
            },
            restrict: 'E',
            replace: true,
            templateUrl: 'monthly-form-indicator.tpl.html',
            controller: controller,
            controllerAs: 'vm',
            bindToController: true
        };

        function controller($location, $anchorScroll, $uibModal, $sce, answerService, companyCertificationService) {
            var vm = this;
            vm.showGraph = false;
            vm.pageInfo = { page: 0, limit: 10 };
            var inputText = vm.indicator.help;
                if(typeof inputText === 'string' || inputText instanceof String){
                    vm.indicator.help = $sce.trustAsHtml(vm.indicator.help);  
                }
            vm.uploadProperties = {
                type: 'evidence',
                accept: '*/*',
                preview: false,
                multiple: true
            };
            vm.openModal = openModal;
            vm.openModalEvidences = openModalEvidences;
            vm.openModalEvidencesZip = openModalEvidencesZip;
            vm.loadAnswers = loadAnswers;
            vm.updateEnabledByUser = updateEnabledByUser;
            vm.toggleShowTotalByEvidence = toggleShowTotalByEvidence;
            vm.toggleTooltipText = toggleTooltipText;

            if (typeof vm.indicator.answers === 'undefined') {
                loadAnswers();
            }

            function openModal(answer) {
                var modal = $uibModal.open({
                    animation: true,
                    component: 'formIndicatorRecord',
                    resolve: {
                        indicators: function () {
                            return angular.copy(vm.indicator.form.subIndicators);
                        },
                        parent: answer
                    }
                });

                modal.result.then(function (indicators) {
                    save(indicators, answer);
                });
            }

            function openModalEvidences(id, title, month) {
                if (id != null && title != null && month != null) {
                    $("#modal-agregar-evidencias-titulo").html(title);
                    $("#slt-mes-modal-agregar-evidencias").val(month);
                    $("#txt-answer-id-modal-agregar-evidencias").val(id);
                }

                $("#amount-div").toggle(vm.indicator.answer.allowCostsRow);
                
                $("#consumo-div").toggle(true);
                $("#description-div").toggle(false)
                
                $("#monto-evidencias").text("");
                $("#consumo-evidencias").text("");
                $("#fecha-evidencias").text("");
                $("#imagen-evidencias").text("");

                $("#txt-description-modal-agregar-evidencias").val('');
                $("#txt-monto-modal-agregar-evidencias").val('');
                $("#txt-consumo-modal-agregar-evidencias").val('');
                $("#txt-fecha-modal-agregar-evidencias").val('');
                $("#file-imagen-modal-agregar-evidencias").val('');
                $("#btn-seleccionar-archivo-evidencias>span").text("Seleccionar archivo");
                $("#btn-seleccionar-archivo-evidencias>img").attr("src", "../img/content/adjuntar.png");

                $("#lbl-evidencias-adjuntas").hide();
                $("#modal-agregar-evidencias").modal();
                data = {
                    "indicatorAnswerId": $("#txt-answer-id-modal-agregar-evidencias").val(),
                    "month": $("#slt-mes-modal-agregar-evidencias").val()
                }
                $("#evidencias").html("<div class='modal-loader'></div>");

                answerService.getMonthlyEvidence(data)
                    .then(success)
                    .catch(error);

                function success(response) {
                    if (response.length <= 0) {
                        $("#evidencias").html("");
                    } else {
                        var html = '';
                        var index = 0;
                        $("#count-evidencias").text(response.length);
                        $("#lbl-evidencias-adjuntas").show();
                        while (index < response.length) {
                            var evidenceDiv = '<div id="evidence-' + response[index].id + '" class="item-evidencia">';
                            if (response[index].path) {
                                evidenceDiv = '<a href="' + response[index].path + '" target="_blank"><div class="div-image"  style="background-image: url(' + response[index].path + ')"></div></a>';
                            } else {
                                evidenceDiv = '<div class="no-evidence-div"><img src="../img/svg/image-not-found.svg" alt="Sin evidencia"><span>Sin evidencia</span></div>';
                            }
                            html += '<div id="evidence-' + response[index].id + '" class="item-evidencia">' + evidenceDiv +
                                '<div class="dropdown">' +
                                '<button class="btn btn-evidence" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">' +
                                new Date(response[index].fecha).toLocaleDateString('es-CR', { day: '2-digit', month: '2-digit', year: 'numeric' }) +
                                '<span>•••</span>' +
                                '</button>' +
                                '<ul class="dropdown-menu">' +
                                '<li><a class="dropdown-item evidence-item" evidence-id="' + response[index].id + '" monto="' + response[index].amount + '" consumo="' + response[index].consumption + '" fecha="' + response[index].fecha + '" onclick="$(this).attr(\'edit\',1); $(\'#editEvidence\').click()">' +
                                '<div>Editar Evidencia <img src="../img/content/editar.png"></div>' +
                                '</a>' +
                                '<input type="file" name="" id="" style="display:none">' +
                                '</li>' +
                                '<li><a class="dropdown-item" data-toggle="modal" data-target="#modal-eliminar-evidencia" onclick="$(\'#btn-remove-evidence\').attr(\'evidence-id\', ' + response[index].id + ')">' +
                                '<div>Eliminar Evidencia <img src="../img/content/basurero.png"></div>' +
                                '</a>' +
                                '</li>' +
                                '</ul>' +
                                '</div>' +
                                '</div>'
                            index++;
                        }

                        $("#evidencias").html(html);
                    }
                }

                function error(error) {
                    console.log(error);
                }
            }

            function openModalEvidencesZip(id, title, month) {
                $("#modal-descargar-evidencias-titulo").html(title);
                $("#btn-evidences-zip").attr("answer-id", id);
                $("#btn-evidences-zip").attr("month", month);

                $("#modal-descargar-evidencias").modal();
            }

            function save(indicators, answer) {
                if (typeof answer === 'undefined') {
                    answer = {};
                    vm.indicator.answer.lines.push(answer);
                }

                answer.subAnswers = [];
                saveIndicatorAnswer(0);

                function saveIndicatorAnswer(i) {
                    if (i >= indicators.length) {
                        saveFormAnswer(answer);
                        return;
                    }

                    answerService.save(indicators[i])
                        .then(success)
                        .catch(error);

                    function success(response) {
                        answer.subAnswers.push({ id: response.id });
                        saveIndicatorAnswer(++i);
                    }

                    function error(error) {
                        console.log(error);
                    }
                }

                function saveFormAnswer(answer) {
                    answerService.save({
                        id: vm.indicator.form.id,
                        type: vm.indicator.form.type,
                        answer: answer
                    })
                        .then(success)
                        .catch(error);

                    function success(response) {
                        answer.item = {
                            id: response.id,
                            summary: response.summary
                        };
                        delete answer.id;
                        delete answer.subAnswers;
                        delete answer.indicator;
                        delete answer.companyCertification;
                        // console.log(vm.indicator.answers);
                    }

                    function error(error) {
                        console.log(error);
                    }

                }
            }

            function loadAnswers() {
                function success(data) {
                    if (data.lines.page === 1) {
                        vm.indicator.answer = {
                            id: data.id,
                            enabledByUser: data.enabledByUser,
                            showTotalByEvidence: data.showTotalByEvidence,
                            evidences: data.evidences,
                            unitOfMeasure: data.unitOfMeasure,
                            lines: data.lines.entities,
                            linesCosts: data.linesCosts.entities,
                            allowCostsRow: data.allowCostsRow,
                        };
                        updateCompletedMonthlyIndicators();
                    } else {
                        Array.prototype.push.apply(vm.indicator.answer.lines, data.lines.entities);
                    }
                    vm.pageInfo.page = data.lines.page;
                    vm.pageInfo.totalCount = data.lines.totalCount;
                    vm.pageInfo.limit = data.lines.limit;
                    vm.pageInfo.isLoading = false;
                }

                function error() {
                    vm.pageInfo.isLoading = false;
                }

                if (vm.pageInfo.isLoading) {
                    return;
                }

                if (vm.pageInfo.page === 1 && vm.pageInfo.limit === 10) {
                    vm.pageInfo.limit = 50;
                } else {
                    ++vm.pageInfo.page;
                }

                companyCertificationService.loadIndicatorAnswers(vm.companyCertification, vm.indicator.id, vm.pageInfo)
                    .then(success)
                    .catch(error)
                    ;
                vm.pageInfo.isLoading = true;

                $.datetimepicker.setLocale('es');
                $("#txt-fecha-modal-agregar-evidencias").datetimepicker({timepicker:false, format:'d/m/Y', hour : '12', minute :'00'});
            }

            function updateCompletedMonthlyIndicators() {
                if (!vm.indicator.requiredQuestion) {
                    return;
                }

                if (!vm.indicator.answer.enabledByUser) {
                    return;
                }

                /*console.log("###### updateCompletedMonthlyIndicators ######");
                console.log(vm.indicator);*/

                vm.container.requiredMonthlyIndicators += 12;
                var rows = (vm.indicator.answer && vm.indicator.answer.lines) ? vm.indicator.answer.lines.length
                    : 0;
                vm.container.totalMonthlyIndicators += (rows * 12);

                // vm.container.requiredMonthlyIndicators++;

                var completed = true;
                for (var index = 0; index < vm.indicator.answer.lines.length; index++) {
                    var line = vm.indicator.answer.lines[index];
                    completed = answerService.quantityMonthly(line);
                    if (!completed) {
                        break;
                    }
                }

                if (completed && vm.indicator.answer.lines.length > 0) {
                    vm.container.completedMonthlyIndicators += 1;
                }
            }

            function updateEnabledByUser() {
                $location.hash('ci' + vm.indicator.id);
                $anchorScroll();
            }

            function toggleShowTotalByEvidence(id, toggleState){
                $('#btn-aceptar-toggle-evidencia').attr("indicator-answer-id", id);
                $('#mgs-toggle-evidencia').text(toggleTooltipText(!toggleState));
                $('.txt-month-'+id).prop("disabled", !$('#chk-toggle-'+id).is(":checked"));
                $('.cost-month-'+id).prop("disabled", !$('#chk-toggle-'+id).is(":checked"));
                $('#modal-cambiar-toggle-evidencia').modal();
            }

            function toggleTooltipText(flag){
                if(flag){
                    return "Al apagarlo, el sistema no tomará en cuenta los montos de las evidencias y únicamente mostrará el monto global que usted ingrese manualmente en el mes.";
                }else{
                    return "Al encender esta opción, el sistema utilizará los totales de cada una de las evidencias, sumarizandolos como monto total para cada mes.";
                }
            }
            
        }
    });
