(function () {
    'use strict';

    angular
            .module('main')
            .controller('FilterController', FilterController);

    function FilterController($stateParams, certificationService, $uibModal, $translate) {
        var vm = this;
        vm.id = $stateParams.id;
        vm.save = save;
        $translate.use('en');

        function save(filters) {
            var index = 0;
            send();
            
            function send() {
                if(!(index < filters.length)) {
                    openModal();
                    return;
                }
                
                certificationService.saveIndicatorFilter(vm.id, filters[index])
                    .then(success)
                    .catch(error);
            }

            function success() {
                index++;
                send();
            }

            function error(error) {
                alert('sorry we have problems ;(');
                console.log(error);
            }
        }

        function openModal() {
            $uibModal.open({
                templateUrl: 'success-modal.tpl.html',
                size: 'sm',
                backdrop: true,
                controller: controller
            });
            function controller($scope, $state, $uibModalInstance) {
                $scope.close = close;
                function close() {
                    $uibModalInstance.close();
                    $state.reload();
                }
            }
        }
    }
})();