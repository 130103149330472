(function () {
    'use strict';

    angular
        .module('main')
        .factory('user', user);

    function user() {

        return { 
            id: ''
        };
    }
})();