(function () {
    'use strict';

    angular
            .module('main')
            .service('routeService', routeService);

    function routeService() {

        return {
            routeLaravel: routeLaravel,
        };

        function routeLaravel() {
            return 'https://panel-davivienda.susty.org';
        }
    }
})();