angular.module('main')
        .directive('menu', function () {
            return {
                restrict: 'E',
                replace: true,
                templateUrl: 'menu.tpl.html',
                controller: controller
            };

            function controller($scope, $state, $uibModal, user, userService) {
                $scope.redirectTo = redirectTo;
                $scope.openComponent = openComponent;
                $scope.user = user;
                $scope.logout = logout;
                $scope.hasPermissions = hasPermissions;

                function hasPermissions(roles)
                {
                    return userService.hasPermissions(user, roles);
                }

                function redirectTo(route) {
                    $state.go(route);
                    $scope.$uibModalInstance.close();
                }

                function openComponent(name) {
                    $scope.$uibModalInstance.close();
                    $uibModal.open({
                        animation: true,
                        component: name,
                        windowClass: 'right'
                    });
                }

                function logout() {
                    $scope.$uibModalInstance.close();
                    userService.logout();
                }
            }
        });