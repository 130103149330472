(function () {
    'use strict';
    angular
            .module('main')
            .controller('CompanyConfigController', CompanyConfigController);

    function CompanyConfigController(companyService, countryService, industryService, company) {
        var vm = this;
        vm.init = init;

        function init() {
            vm.updatedAt = company.updatedAt;
            vm.model = angular.copy(company);
            delete vm.model.updatedAt;
            vm.uploadProperties = {
                type: 'company-logo',
                accept: '*/*',
                preview: true,
                multiple: false
            };
            vm.numberOfEmployees = ['1-20','20-50','50-100','100-500','más de 500'];
            vm.addCountry = addCountry;
            vm.removeCountry = removeCountry;
            vm.save = save;
            loadCountries();
            loadIndustries();
        }
        
        function save() {
            companyService.update(vm.model).then(success)
                    .catch(error);

            function success() {
                vm.success = true;
                company.name = vm.model.name;
                company.dni = vm.model.dni;
                company.description = vm.model.description;
                company.numberOfEmployees = vm.model.numberOfEmployees;
                company.classificationBySize = vm.model.classificationBySize;
                company.sector = vm.model.sector;
                company.industry = vm.model.industry;
                company.mainCountry = vm.model.mainCountry;
                company.countries = vm.model.countries;
                company.logo = vm.model.logo;
                company.updatedAt = new Date();
            }

            function error(error) {
                console.log(error);
            }
        }
        
        function addCountry(country) {
            if(typeof country === 'undefined' || country === null) {
                return;
            }
            if(vm.model.countries.indexOf(country) !== -1) {
                return;
            }
            vm.model.countries.push(country);
            vm.country = null; 
       }
        
        function removeCountry(country) {
            var index = vm.model.countries.indexOf(country);
            vm.model.countries.splice(index, 1);
        }

        function loadCountries() {
            countryService.findAll()
                    .then(success)
                    .catch(error);

            function success(response) {
                vm.countries = response;
            }

            function error(error) {
                console.log(error);
            }
        }

        function loadIndustries() {
            industryService.findAll()
                    .then(success)
                    .catch(error);

            function success(response) {
                vm.industries = response;
            }

            function error(error) {
                console.log(error);
            }
        }
    }

})();