(function () {
    'use strict';

    angular
            .module('main')
            .controller('PasswordRecoveryController', PasswordRecoveryController);

    function PasswordRecoveryController(userService) {
        var vm = this;
        vm.resetPassword = resetPassword;
        
        function resetPassword(model, form) {
            userService.resetPassword(model).then(success, error);

            function success() {
                vm.message = {
                    type: 'success',
                    text: 'se te envio una nueva contraseña'
                };
                vm.user = {};
                form.$setPristine();
            }

            function error(error) {
                vm.message = {
                    type: 'danger',
                    text: 'el correo ingresado no es valido'
                };
                console.log(error);
            }
        }
    }

})();