angular.module('main')
    .directive('emissionOutsourced', function() {
        return {
            scope: {
                indicator: '=',
                container: '=',
                id: '=',
                openModalEvidences: '=',
                openModalEvidencesZip: '=',
                month: '='
            },
            restrict: 'E',
            replace: true,
            templateUrl: 'emission-outsourced.tpl.html',
            controller: controller,
            controllerAs: 'vm',
            bindToController: true
        };

        function controller($uibModal,companyCertificationService,companyCertification) {
            var vm = this;
            vm.save = save;
            vm.specificSummaryf = specificSummary;
            vm.addEmissionDirect = addEmissionDirect;
            vm.updateEquipmentFilter = updateEquipmentFilter;
            vm.selectedMonth = vm.month[0];
           vm.showConfirmation = showConfirmation;
            vm.showEquiomentList = showEquiomentList;
            vm.emissionActivities = [];
            vm.transports = [
                { id: 0, name: 'Transp. de personal' },
                { id: 1, name: 'Transp. y distribución' }
            ];
            vm.selectedTransport = vm.transports[0];

            vm.significantCheck = false;
            vm.summary = true;
            vm.setSummary = function() {
                vm.summary =  !vm.summary;
            };
            if (typeof vm.indicator.answers === 'undefined') {
                loadAnswers();
                loadEquipmentFilter();
            } else {
                loadAnswers();
                loadEquipmentFilter();
            }

            function loadAnswers() {
                companyCertificationService.loadIndicatorAnswersCalculator(vm.id, vm.indicator.id, 'direct-emission',1,companyCertification.year)
                    .then(success)
                    .catch(error);

                function success(response) {
                    vm.indicator.answers = response.data;
                    loadEmissionActivities();
                    emissionSummary();
                }

                function error(error) {
                    console.log(error);
                }
            }



            function loadEquipmentFilter() {
                companyCertificationService.loadEquipmentFilter(vm.id,'calcu_013',2)
                    .then(success)
                    .catch(error);

                function success(response) {
                    vm.equipments = response.data;
                }

                function error(error) {
                    console.log(error);
                }
            }

            function save() {
                data = {
                    "indicator_id": vm.indicator.id,
                    "emission_direct_type": 'tercerizada',
                    "emission": 'significant',
                    "company_certification_id": vm.id,
                    "answers": vm.indicator.answers,
                    "transport_type": vm.selectedTransport.id,
                };
                console.log('Type', vm.selectedTransport.id);
                companyCertificationService.saveIndicatorAnswersCalculator(data, 'direct-emission')
                    .then(success)
                    .catch(error);

                function success(response) {
                    loadAnswers();
                }

                function error(error) {
                    console.log(error);
                }
            }

            function addEmissionDirect() {
                data = {
                    "indicator_id": vm.indicator.id,
                    "emission_direct_type": 'tercerizada',
                    "emission": 'direct',
                    "company_certification_id": vm.id
                };

                companyCertificationService.addResource(data, 'direct-emission')
                    .then(success)
                    .catch(error);

                function success(response) {
                    loadAnswers();
                    loadEquipmentFilter();
                }

                function error(error) {
                    console.log(error);
                }

            }

            function loadEmissionActivities() {
            /*     companyCertificationService.loadEmissionActivities()
                    .then(success)
                    .catch(error);

                function success(response) {
                    vm.emissionActivities = response.data;
                    vm.emissionActivitiesSelect = response.data[0];
                    specificSummary();
                }

                function error(error) {
                    console.log(error);
                } */
                for (var index = 0; index < vm.indicator.answers.length; index++) {
                    if (typeof vm.indicator.answers[index].equipment !== 'undefined') {
                        if (typeof vm.indicator.answers[index].equipment.emission_activity !== 'undefined') {
                            vm.emissionActivities.push(vm.indicator.answers[index].equipment.emission_activity);
                        }
                    }    
                }

                  vm.emissionActivitiesSelect =  vm.emissionActivities[0];
                  specificSummary();
            }


            function specificSummary() {
                vm.specificSummary = [];

                const volatil = [];

                for (var index = 0; index < vm.indicator.answers.length; index++) {
                    if (typeof vm.indicator.answers[index].equipment !== 'undefined') {
                        if (typeof vm.indicator.answers[index].equipment.emission_activity !== 'undefined') {
                            if (vm.indicator.answers[index].equipment.emission_activity.id == vm.emissionActivitiesSelect.id) {
                                volatil.push(vm.indicator.answers[index]);
                            }
                        }
                    }    
                }

                var equipos = '';
                var total =0;
                var unidades ='';

                var c02 = 0;
                var ch4 = 0;
                var n20 = 0;

                
                vm.specificSummary['potencial_c02'] = 1 ;
                vm.specificSummary['potencial_ch4'] = 21;
                vm.specificSummary['potencial_n20'] = 310;

                for (var index = 0; index < volatil.length; index++) {
                    equipos += volatil[index].equipment.name+ ' ,'; 
                    if (typeof vm.indicator.answers[index].equipment.unit_of_measure.name !== 'undefined') {
                        unidades += volatil[index].equipment.unit_of_measure.name+ ' ,'; 
                    }
                    var totalEquipoConsumo =  totalConsumo(volatil[index].month_emission);

                     vm.specificSummary['factor_c02'] = volatil[index].equipment.emission_activity.emissionFactor.co2 ;
                     vm.specificSummary['factor_ch4'] = volatil[index].equipment.emission_activity.emissionFactor.ch4;
                     vm.specificSummary['factor_n20'] = volatil[index].equipment.emission_activity.emissionFactor.n2o;
                   
                     total += totalEquipoConsumo;
                     c02 += (totalEquipoConsumo * volatil[index].equipment.emission_activity.emissionFactor.co2 *  vm.specificSummary['potencial_c02']);
                     ch4 += (totalEquipoConsumo * volatil[index].equipment.emission_activity.emissionFactor.ch4 *  vm.specificSummary['potencial_ch4']);
                     n20 +=(totalEquipoConsumo * volatil[index].equipment.emission_activity.emissionFactor.n2o*  vm.specificSummary['potencial_n20']);

               }

               vm.specificSummary['equipos'] = equipos ;
               vm.specificSummary['unidades'] = unidades;
               vm.specificSummary['totalConsumo'] = total ;
               vm.specificSummary['c02'] = c02.toFixed(3) ;
               vm.specificSummary['ch4'] = ch4.toFixed(3);
               vm.specificSummary['n20'] = n20.toFixed(3) ;
               vm.specificSummary['totalc02e'] = ((n20+ ch4 +c02) / 1000).toFixed(3);
            }
            function emissionSummary() {
                vm.emissionSummary = [];

                const volatil = [];
              
                var total =0;
                var unidades ='';

                var c02 = 0;
                var ch4 = 0;
                var n20 = 0;
                for (var index = 0; index < vm.indicator.answers.length; index++) {
                    if (typeof vm.indicator.answers[index].equipment !== 'undefined') {
                        if (typeof vm.indicator.answers[index].equipment.unit_of_measure.name !== 'undefined') {
                            unidades =  vm.indicator.answers[index].equipment.unit_of_measure.name+ ' ,'; 
                            var totalEquipoConsumo =  totalConsumo( vm.indicator.answers[index].month_emission);
                            
                           
                             total += totalEquipoConsumo;
                             c02 += (totalEquipoConsumo * vm.indicator.answers[index].equipment.emission_activity.emissionFactor.co2) + (totalEquipoConsumo * 1);
                             ch4 += (totalEquipoConsumo * vm.indicator.answers[index].equipment.emission_activity.emissionFactor.ch4) + (totalEquipoConsumo * 21);
                             n20 +=(totalEquipoConsumo *  vm.indicator.answers[index].equipment.emission_activity.emissionFactor.n2o) + (totalEquipoConsumo * 301); 
                        }
                     }
                }


               vm.emissionSummary['unidad'] = unidades ;
               vm.emissionSummary['totalConsumo'] = total ;
               vm.emissionSummary['totalSummary'] = ((n20+ ch4 +c02) / 1000).toFixed(3);

            }

            function totalConsumo(emission) {
                var total = 0;

                total += typeof emission.january.consumo === 'undefined' ? 0 : parseInt(  emission.january.consumo );
                total += typeof emission.february.consumo === 'undefined' ? 0 :parseInt( emission.february.consumo);
                total += typeof emission.march.consumo === 'undefined' ? 0 :parseInt( emission.march.consumo);
                total += typeof emission.april.consumo === 'undefined' ? 0 : parseInt(emission.april.consumo);
                total += typeof emission.may.consumo === 'undefined' ? 0 :parseInt( emission.may.consumo);
                total += typeof emission.june.consumo === 'undefined' ? 0 :parseInt( emission.june.consumo);
                total += typeof emission.july.consumo === 'undefined' ? 0 : parseInt(emission.july.consumo);
                total += typeof emission.august.consumo === 'undefined' ? 0 :parseInt( emission.august.consumo);
                total += typeof emission.september.consumo === 'undefined' ? 0 : parseInt(emission.september.consumo);
                total += typeof emission.october.consumo === 'undefined' ? 0 :parseInt( emission.october.consumo);
                total += typeof emission.november.consumo === 'undefined' ? 0 : parseInt(emission.november.consumo);
                total += typeof emission.december.consumo === 'undefined' ? 0 :parseInt( emission.december.consumo);
                return total;
            }

            function updateEquipmentFilter(oldE,newE,key) {
                if ( newE != null) {
                    for (var index = 0; index <   vm.equipments.length; index++) {
                        if(vm.equipments[index].id == oldE.id){
                         vm.equipments[index].notAnOption = 0;
                        }
                        if(vm.equipments[index].id == newE.id){
                         vm.equipments[index].notAnOption = 1;
                        }
                     }
                }else{
                    vm.indicator.answers[key].equipment =  oldE;
                }
              }

             function showConfirmation(answer, key) {
                $uibModal.open({
                    animation: true,
                    component: 'deleteAnswerEmission',
                    resolve: {
                        emissionAnswer: function () {
                            return answer;
                        },
                        key: function () {
                            return key;
                        },
                        loadAnswers: function () {
                            return loadAnswers;
                        }
                    }
                });
              }

              function showEquiomentList(answers, key) {
                $uibModal.open({
                    animation: true,
                    component: 'listEquipment',
                    resolve: {
                        equipment: function () {
                            return answers;
                        },
                        key: function () {
                            return key;
                        },
                    }
                });
              }
        }
    });