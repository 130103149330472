(function () {
    'use strict';

    angular
        .module('main')
        .config(config);

    function config($stateProvider) {
        $stateProvider
            .state('main', {
                abstract: true,
                views: {
                    '@': {
                        templateUrl: 'main.tpl.html',
                        controller: 'MainController', 
                        controllerAs: 'vm'
                    },
                    'header@main': {
                        templateUrl: 'header.tpl.html'
                    },
                    'footer@main': {
                        templateUrl: '../footer.tpl.html'
                    }
                }
                
            });
    }
})();