(function () {
    'use strict';

    angular
        .module('main')
        .factory('companyCertification', companyCertification);

    function companyCertification() {

        return { 
            id: null
        };
    }
})();