(function () {
    'use strict';

    angular
      .module('main')
      .config(config);

    function config($stateProvider) {
        $stateProvider
        .state('scheme', {
            url: '/certifications/:id/scheme',
            templateUrl: 'scheme.tpl.html',
            controller: 'SchemeController', 
            controllerAs: 'vm'
        });
    }
})();