angular.module('main')
    .directive('progressFromOthersCertifications', function () {
        return {
            restrict: 'E',
            templateUrl: 'progress-from-others-certifications.tpl.html',
            controller: controller
        };

        function controller($scope, companyCertificationService, $stateParams, answerService, indicatorService) {
            $scope.load = load;
            $scope.removeEvidence = removeEvidence;
            $scope.saveEvidence = saveEvidence;
            $scope.fnFileEvidence = fnFileEvidence;
            $scope.returnEvidence = returnEvidence;
            $scope.fnChangeSubirEvidencias = fnChangeSubirEvidencias;
            $scope.changeEvidenceMonth = changeEvidenceMonth;
            $scope.downloadEvidencesZip = downloadEvidencesZip;
            $scope.editEvidence = editEvidence;
            $scope.openEvidenceDatePicker = openEvidenceDatePicker;
            $scope.closeConfirmation = closeConfirmation;
            $scope.aceptarToggleEvidence = aceptarToggleEvidence;
            $scope.cancelarToggleEvidence = cancelarToggleEvidence;

            function removeEvidence() {
                id = $("#btn-remove-evidence").attr("evidence-id");

                answerService.removeMonthlyEvidence(id)
                    .then(success)
                    .catch(error);

                function success() {
                    $("#evidence-" + id).remove();
                    newCount = parseInt($("#count-evidencias").text()) - 1;
                    $("#count-evidencias").text(newCount);
                    $("#modal-eliminar-evidencia").modal('hide');
                    $("body").addClass("modal-open");
                }

                function error(error) {
                    console.log(error);
                }
            }

            function saveEvidence() {
                if (checkFields()) {
                    console.log('entre');
                    var formData = new FormData();
                    var fecha = $("#txt-fecha-modal-agregar-evidencias").datetimepicker('getValue');
                    formData.append('indicatorAnswerId', $("#txt-answer-id-modal-agregar-evidencias").val());
                    formData.append('emissionAnswerId', $("#txt-emission-answer-id-modal-agregar-evidencias").val());
                    formData.append('month', $("#slt-mes-modal-agregar-evidencias").val() || "0");
                    formData.append('amount', $("#txt-monto-modal-agregar-evidencias").val() || "0");
                    formData.append('consumption', $("#txt-consumo-modal-agregar-evidencias").val());
                    formData.append('fecha', fecha.getFullYear()+"-"+(fecha.getMonth()+1)+"-"+fecha.getDate());
                    formData.append('file', $("#file-imagen-modal-agregar-evidencias").prop('files')[0]);
                    formData.append('description', $("#txt-description-modal-agregar-evidencias").val() || "");

                    if ($("#txt-id-evidence-modal-agregar-evidencias").val() == "") {
                        answerService.saveMonthlyEvidence(formData)
                            .then(success)
                            .catch(error);
                    } else {
                        
                        formData.append('id', $("#txt-id-evidence-modal-agregar-evidencias").val());
                        $("#txt-id-evidence-modal-agregar-evidencias").val("");
                        answerService.updateMonthlyEvidence(formData)
                            .then(openModalEvidences)
                            .catch(error);
                    }

                    function success(response) {
                        newCount = parseInt($("#count-evidencias").text()) + 1;
                        var evidenceDiv = '<a href="' + response.path + '" target="_blank"><div class="div-image"  style="background-image: url(' + response.path + ')"></div></a>';
                        if (!response.path) {
                            evidenceDiv = '<div class="no-evidence-div"><img src="../img/svg/image-not-found.svg" alt="Sin evidencia"><span>Sin evidencia</span></div>';
                        }
                        $("#count-evidencias").text(newCount);
                        $("#evidencias").append(
                            '<div id="evidence-' + response.id + '" class="item-evidencia">' + evidenceDiv +
                            '<div class="dropdown">' +
                            '<button class="btn btn-evidence" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">' +
                            new Date(response.fecha).toLocaleDateString('es-CR', { day: '2-digit', month: '2-digit', year: 'numeric' }) +
                            '<span>•••</span>' +
                            '</button>' +
                            '<ul class="dropdown-menu">' +
                            '<li><a class="dropdown-item evidence-item" evidence-id="' + response.id +'" descripcion="' +  response.description +'" monto="' + response.amount + '" consumo="' + response.consumption + '" fecha="' + response.fecha + '" onclick="$(this).attr(\'edit\',1); $(\'#editEvidence\').click()">' +
                            '<div>Editar Evidencia <img src="../img/content/editar.png"></div>' +
                            '</a>' +
                            '<input type="file" name="" id="" style="display:none">' +
                            '</li>' +
                            '<li><a class="dropdown-item" data-toggle="modal" data-target="#modal-eliminar-evidencia" onclick="$(\'#btn-remove-evidence\').attr(\'evidence-id\', ' + response.id + ')">' +
                            '<div>Eliminar Evidencia <img src="../img/content/basurero.png"></div>' +
                            '</a>' +
                            '</li>' +
                            '</ul>' +
                            '</div>' +
                            '</div>'
                        );

                        $("#txt-monto-modal-agregar-evidencias").val('');
                        $("#txt-consumo-modal-agregar-evidencias").val('');
                        $("#txt-fecha-modal-agregar-evidencias").val('');
                        $("#txt-description-modal-agregar-evidencias").val('');
                        $("#file-imagen-modal-agregar-evidencias").val('');
                        $("#btn-seleccionar-archivo-evidencias>span").text("Seleccionar archivo");
                        $("#btn-seleccionar-archivo-evidencias>img").attr("src", "../img/content/adjuntar.png");

                    }

                    function error(error) {
                        console.log(error);
                    }
                }
                console.log('falle');

            }

            function returnEvidence(){
                id = $("#txt-answer-id-modal-agregar-evidencias").val();
                month = $("#slt-mes-modal-agregar-evidencias").val();

                total = 0;
                totalCosts = 0;
                $.each($('.evidence-item'), function(){
                    total += parseInt($(this).attr('monto'));
                    totalCosts += parseInt($(this).attr('consumo'));
                });
                // Changes total for consumptions.
                $('#txt-month-'+month+'-'+id+":disabled").val(total);
                //change ng-model
                $('#txt-month-'+month+'-'+id+":disabled").trigger('input'); // Use for Chrome/Firefox/Edge
                $('#txt-month-'+month+'-'+id+":disabled").trigger('change');

                // Changes total for costs.
                $('#cost-month-'+month+'-'+id+":disabled").val(totalCosts);
                $('#cost-month-'+month+'-'+id+":disabled").trigger('input');
                $('#cost-month-'+month+'-'+id+":disabled").trigger('change');
                
                $("#modal-agregar-evidencias").modal('hide');
            }

            function checkFields() {
                result = true;
                var regExp = new RegExp("^\\d+$");
                if ($("#txt-description-modal-agregar-evidencias").val() != "")  {
                    $("#txt-consumo-modal-agregar-evidencias").val(0);
                }


                if (!$("#txt-monto-modal-agregar-evidencias").is(":hidden")) {
                    if ($("#txt-monto-modal-agregar-evidencias").val() == "") {
                        $("#monto-evidencias").text("* Este campo es requerido.");
                        result = false;
                    } else {
                        if (!regExp.test($("#txt-monto-modal-agregar-evidencias").val())) {
                            $("#monto-evidencias").text("* Solo se permiten números.");
                            result = false;
                        }
                        else {
                            $("#monto-evidencias").text("");
                        }
                    }
                }
                if ($("#txt-consumo-modal-agregar-evidencias").val() == "" && $("#txt-description-modal-agregar-evidencias").val() == "") {
                    $("#consumo-evidencias").text("* Este campo es requerido.");
                    result = false;
                } else {
                    if (!regExp.test($("#txt-consumo-modal-agregar-evidencias").val())) {
                        $("#consumo-evidencias").text("* Solo se permiten números.");
                        result = false;
                    }
                    else {
                        $("#consumo-evidencias").text("");
                    }
                }
                if ($("#txt-fecha-modal-agregar-evidencias").val() == "") {
                    $("#fecha-evidencias").text("* Este campo es requerido.");
                    result = false;
                }
                else {
                    $("#fecha-evidencias").text("");
                }

                return result;
            }

            function changeEvidenceMonth() {
                $("#monto-evidencias").text("");
                $("#consumo-evidencias").text("");
                $("#description-evidencias").text("");
                $("#fecha-evidencias").text("");
                $("#imagen-evidencias").text("");

                month = $("#slt-mes-modal-agregar-evidencias").val();
                id = $("#txt-answer-id-modal-agregar-evidencias").val();
                emissionAnswerId = $("#txt-emission-answer-id-modal-agregar-evidencias").val();

                $("#lbl-evidencias-adjuntas").hide();

                data = {
                    "indicatorAnswerId": id,
                    "month": month,
                    "emissionAnswerId": emissionAnswerId,
                }

                $("#evidencias").html("<div class='modal-loader'></div>");

                answerService.getMonthlyEvidence(data)
                    .then(success)
                    .catch(error);

                function success(response) {
                    if (response.length <= 0) {
                        $("#evidencias").html("");
                    } else {
                        var html = '';
                        var index = 0;
                        $("#count-evidencias").text(response.length);
                        $("#lbl-evidencias-adjuntas").show();
                        while (index < response.length) {
                            var evidenceDiv = '<a href="' + response[index].path + '" target="_blank"><div class="div-image"  style="background-image: url(' + response[index].path + ')"></div></a>';
                            if (!response[index].path) {
                                evidenceDiv = '<div class="no-evidence-div"><img src="../img/svg/image-not-found.svg" alt="Sin evidencia"><span>Sin evidencia</span></div>';
                            }
                            html += '<d id="evidence-' + response[index].id + '" class="item-evidencia">' + evidenceDiv +
                                '<div class="dropdown">' +
                                '<button class="btn btn-evidence" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">' +
                                new Date(response[index].fecha).toLocaleDateString('es-CR', { day: '2-digit', month: '2-digit', year: 'numeric' }) +
                                '<span>•••</span>' +
                                '</button>' +
                                '<ul class="dropdown-menu">' +
                                '<li><a class="dropdown-item evidence-item" evidence-id="' + response[index].id + '" descripcion="' + response[index].description +'" monto="' + response[index].amount + '" consumo="' + response[index].consumption + '" fecha="' + response[index].fecha + '" onclick="$(this).attr(\'edit\',1); $(\'#editEvidence\').click()">' +
                                '<div>Editar Evidencia <img src="../img/content/editar.png"></div>' +
                                '</a>' +
                                '<input type="file" name="" id="" style="display:none">' +
                                '</li>' +
                                '<li><a class="dropdown-item" data-toggle="modal" data-target="#modal-eliminar-evidencia" onclick="$(\'#btn-remove-evidence\').attr(\'evidence-id\', ' + response[index].id + ')">' +
                                '<div>Eliminar Evidencia <img src="../img/content/basurero.png"></div>' +
                                '</a>' +
                                '</li>' +
                                '</ul>' +
                                '</div>' +
                                '</div>'
                            index++;
                        }

                        $("#evidencias").html(html);
                    }
                }

                function error(error) {
                    console.log(error);
                }
            }

            function editEvidence() {
                $element = $('.evidence-item[edit=1]');
                $element.removeAttr('edit');
                id = $element.attr('evidence-id');
                monto = $element.attr('monto');
                consumo = $element.attr('consumo');
                description = $element.attr('descripcion');
                var d = new Date($element.attr('fecha'));
                var curr_date = d.getDate();
                var curr_month = (d.getMonth() + 1).length > 1 ? (d.getMonth() + 1) : "0" + (d.getMonth() + 1);
                var curr_year = d.getFullYear();
                fecha = (curr_year + "-" + curr_month + "-" + curr_date);
                $("#txt-fecha-modal-agregar-evidencias").val(fecha);
                $("#txt-id-evidence-modal-agregar-evidencias").val(id);
                $("#txt-monto-modal-agregar-evidencias").val(monto);
                $("#txt-consumo-modal-agregar-evidencias").val(consumo);
                $("#txt-description-modal-agregar-evidencias").val(description);
            }

            function downloadEvidencesZip() {

                data = {
                    indicatorAnswerId: $("#btn-evidences-zip").attr("answer-id"),
                    month: $("#btn-evidences-zip").attr("month"),
                    emissionAnswerId: $("#btn-evidences-zip").attr("emission-id")
                }
                answerService.downloadMonthRecord(data)
                    .then(success)
                    .catch(error);

                function success(response) {
                    window.location = response;
                }

                function error(error) {
                    console.log(error);
                }
            }

            function fnFileEvidence() {
                if ($("#btn-seleccionar-archivo-evidencias").attr("mode") == "0") {
                    $("#file-imagen-modal-agregar-evidencias").click();
                    $("#btn-seleccionar-archivo-evidencias").attr("mode", "1");
                } else {
                    $("#file-imagen-modal-agregar-evidencias").val('');
                    $("#btn-seleccionar-archivo-evidencias>span").text("Seleccionar archivo");
                    $("#btn-seleccionar-archivo-evidencias>img").attr("src", "../img/content/adjuntar.png");
                    $("#btn-seleccionar-archivo-evidencias").attr("mode", "0");
                }
            }

            function fnChangeSubirEvidencias() {
                $("#btn-seleccionar-archivo-evidencias>span").text($("#file-imagen-modal-agregar-evidencias").val().split('\\').pop());
                $("#btn-seleccionar-archivo-evidencias>img").attr("src", "../img/content/basurero.png");
            }

            function openModalEvidences(id, title, month) {
                if (id != null && title != null && month != null) {
                    $("#modal-agregar-evidencias-titulo").html(title);
                    $("#slt-mes-modal-agregar-evidencias").val(month);
                    $("#txt-answer-id-modal-agregar-evidencias").val(id);
                }

                $("#monto-evidencias").text("");
                $("#consumo-evidencias").text("");
                $("#fecha-evidencias").text("");
                $("#imagen-evidencias").text("");

                $("#txt-monto-modal-agregar-evidencias").val('');
                $("#txt-description-modal-agregar-evidencias").val('');
                $("#txt-consumo-modal-agregar-evidencias").val('');
                $("#txt-fecha-modal-agregar-evidencias").val('');
                $("#file-imagen-modal-agregar-evidencias").val('');
                $("#btn-seleccionar-archivo-evidencias>span").text("Seleccionar archivo");
                $("#btn-seleccionar-archivo-evidencias>img").attr("src", "../img/content/adjuntar.png");

                $("#lbl-evidencias-adjuntas").hide();
                $("#modal-agregar-evidencias").modal();
                data = {
                    "indicatorAnswerId": $("#txt-answer-id-modal-agregar-evidencias").val(),
                    "month": $("#slt-mes-modal-agregar-evidencias").val(),
                    "emissionAnswerId": $("#txt-emission-answer-id-modal-agregar-evidencias").val(),
                }

                console.log(data);
                $("#evidencias").html("<div class='modal-loader'></div>");

                answerService.getMonthlyEvidence(data)
                    .then(success)
                    .catch(error);

                function success(response) {
                    if (response.length <= 0) {
                        $("#evidencias").html("");
                    } else {
                        var html = '';
                        var index = 0;
                        $("#count-evidencias").text(response.length);
                        $("#lbl-evidencias-adjuntas").show();
                        while (index < response.length) {
                            var evidenceDiv = '<a href="' + response[index].path + '" target="_blank"><div class="div-image"  style="background-image: url(' + response[index].path + ')"></div></a>';
                            if (!response[index].path) {
                                evidenceDiv = '<div class="no-evidence-div"><img src="../img/svg/image-not-found.svg" alt="Sin evidencia"><span>Sin evidencia</span></div>';
                            }
                            html += '<div id="evidence-' + response[index].id + '" class="item-evidencia">' + evidenceDiv +
                                '<div class="dropdown">' +
                                '<button class="btn btn-evidence" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">' +
                                new Date(response[index].fecha).toLocaleDateString('es-CR', { day: '2-digit', month: '2-digit', year: 'numeric' }) +
                                '<span>•••</span>' +
                                '</button>' +
                                '<ul class="dropdown-menu">' +
                                '<li><a class="dropdown-item evidence-item" evidence-id="' + response[index].id + '" descripcion="' + response[index].description + '" monto="' + response[index].amount + '" consumo="' + response[index].consumption + '" fecha="' + response[index].fecha + '" onclick="$(this).attr(\'edit\',1); $(\'#editEvidence\').click()">' +
                                '<div>Editar Evidencia <img src="../img/content/editar.png"></div>' +
                                '</a>' +
                                '<input type="file" name="" id="" style="display:none">' +
                                '</li>' +
                                '<li><a class="dropdown-item" data-toggle="modal" data-target="#modal-eliminar-evidencia" onclick="$(\'#btn-remove-evidence\').attr(\'evidence-id\', ' + response[index].id + ')">' +
                                '<div>Eliminar Evidencia <img src="../img/content/basurero.png"></div>' +
                                '</a>' +
                                '</li>' +
                                '</ul>' +
                                '</div>' +
                                '</div>'
                            index++;
                        }

                        $("#evidencias").html(html);
                    }
                }

                function error(error) {
                    console.log(error);
                }
            }

            function openEvidenceDatePicker(){
                $("#txt-fecha-modal-agregar-evidencias").datetimepicker("show");
            }

            function aceptarToggleEvidence(){
                id = $('#btn-aceptar-toggle-evidencia').attr('indicator-answer-id'); 
                indicatorService.toggleShowTotalByEvidence(id)
                    .then(success)
                    .catch(error);

                function success(data) {
                    $('.txt-month-'+id).prop("disabled", data);
                    $('.cost-month-'+id).prop("disabled", data);
                    $('#modal-cambiar-toggle-evidencia').modal('hide');
                }

                function error(error) {
                    console.log(error);
                }
            }

            function cancelarToggleEvidence(){
                id = $('#btn-aceptar-toggle-evidencia').attr('indicator-answer-id');          
                $('#chk-toggle-'+id).prop('checked',!$('#chk-toggle-'+id).is(":checked"));
                
                $('#modal-cambiar-toggle-evidencia').modal('hide');
            }

            function load() {
                companyCertificationService.loadProgressFromOthersCertifications($stateParams.id).then(success, error);

                function success(response) {
                    $scope.items = response;
                }

                function error(error) {
                    console.log(error);
                }
            }

            function closeConfirmation(){
                $("#modal-eliminar-evidencia").modal('hide');
                $("body").addClass("modal-open");
            }
        }
    });