(function () {
    'use strict';

    angular
      .module('main')
      .config(config);

    function config($stateProvider) {
        $stateProvider
        .state('newCompany', {
            parent: 'singlePage',
            url: '/new-company',
            templateUrl: 'new-company.tpl.html',
            controller: 'NewCompanyController', 
            controllerAs: 'vm'
        });
    }
})();