(function () {
    'use strict';

    angular
            .module('main')
            .service('containerService', containerService);

    function containerService($q, $http) {

        return {
            findAll: findAll,
            findSubContainers: findSubContainers,
            getContainersByCertification: getContainersByCertification
        };

        function findAll() {
            var deferred = $q.defer();

            $http.get('/api/containers', {
                headers: {skipAuthorization: true}
            }).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function findSubContainers(id, ccid, skipAuthorization) { //
            if(typeof skipAuthorization === 'undefined') {
                skipAuthorization = true;
            }
            var deferred = $q.defer();

            var path = '/api/containers/' + id + '/sub-containers';
            if(typeof ccid !== 'undefined' && ccid !== '') {
                path = path + '/'+ccid;
            }
            $http.get(path, {
                headers: {skipAuthorization: skipAuthorization}
            }).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        /**
         * Search a sertification by certificationId
         * @todo: add cache
         * @param companyId
         * @param certificationId
         */
        function getContainersByCertification(companyId, certificationId){
            var deferred = $q.defer();
            $http.get('/api/' + certificationId + '/' + companyId + '/subcontainers').then(
                function(response){deferred.resolve(response.data);},
                function(error){deferred.reject(error);}
            );
            return deferred.promise;
        }
    }
})();
