angular.module('main')
        .directive('userProfile', function () {
            return {
                restrict: 'E',
                replace: true,
                templateUrl: 'user-profile.tpl.html',
                controller: controller,
                controllerAs: 'vm',
                bindToController: true
            };
    
            function controller(company, userService) {
                var vm = this;
                vm.company = company;
                vm.changePassword = changePassword;
                
                function changePassword(model, form) {
                    userService.changePassword(model).then(success, error);

                    function success() {
                        vm.message = {
                            type: 'success',
                            text: 'su contraseña fue cambiada'
                        };
                        form.$setPristine();
                    }

                    function error(error) {
                        vm.message = {
                            type: 'danger',
                            text: 'la contraseña ingresada no coincide'
                        };
                        console.log(error);
                    }
                }
            }
        });