(function () {
    'use strict';

    angular
        .module('main')
        .factory('OAuthInterceptor', OAuthInterceptor);

    function OAuthInterceptor($injector, $q) {
        return { 
            request: request,
            responseError: responseError
        };

        function request(config) {
            var userService = $injector.get('userService');

            if (config.headers.skipAuthorization){
                delete config.headers.skipAuthorization;
                return config;
            }
           
            if (userService.getToken()) {
                config.headers.Authorization = 'Bearer ' + userService.getToken();
            }

            return config;
        }
        
        function responseError (response) {
            var $uibModal = $injector.get('$uibModal');
            switch (response.status) {
                case 401:
                    $uibModal.open({
                        animation: true,
                        component: 'login',
                        resolve: {
                            message: function () {
                                return '¡Necesitas autenticarte para continuar!';
                            }
                        }
                    });
                    break;
                case 403:
                    $uibModal.open({
                        animation: true,
                        component: 'login',
                        resolve: {
                            message: function () {
                                return '¡Tú sesión caduca, por favor ingresa de nuevo!';
                            }
                        }
                    });
                    break;
            }
            return $q.reject(response);
        }
    }
})();