angular.module('main')
        .directive('summaryIndicator', function () {
            return {
                scope: {
                    indicator: '=',
                    container: '=',
                    onClick: '&'
                },
                restrict: 'E',
                templateUrl: 'summary-indicator.tpl.html',
                controller: controller
            };

            function controller($scope, $sce, $filter, companyCertificationService, $stateParams) {
                var inputText = $scope.indicator.help;
                if(typeof inputText === 'string' || inputText instanceof String){
                    $scope.indicator.help = $sce.trustAsHtml($scope.indicator.help);  
                }
                $scope.uploadProperties = {
                    type: 'evidence',
                    accept: '*/*',
                    preview: false,
                    multiple: true
                };

                $scope.applyFormula = applyFormula;
                $scope.loadSubAnswers = loadSubAnswers;
                
                function applyFormula(lines, formula, key) {
                    var operations = formula.operations.replace(/,/g, ' ');

                    var values = {};
                    for(var i=0; i<lines.length; i++) {
                        var value = 0;
                        if(typeof key !== 'undefined') {
                            value += parseFloat(lines[i][key]) || 0;
                        } else {
                            value = $filter('sum')(lines[i]);
                        }
                        values[lines[i].indicator] = values[lines[i].indicator] ? (values[lines[i].indicator] + value) : value;
                    }

                    for (var k in values){
                      var re = new RegExp(k,'g');
                      operations =  operations.replace(re, values[k]);
                    }

                    operations =  operations.replace(new RegExp('LENGTH', 'g'), lines.length);

                    var result = 0;

                    try {
                        result = eval(operations);
                    } catch (e) {
                        console.log(e);
                    }

                    if(!isFinite(result)) {
                        result = 0;
                    }

                    result = isNaN(result) ? 0 : result;
                    result = result % 1 !== 0 ? result.toFixed(2) : result;
                    return result + (formula.symbol ? formula.symbol : '');
                }

                function loadSubAnswers() {
                    companyCertificationService.loadIndicatorSummary($stateParams.id, $scope.indicator.id).then(success, error);

                    function success(response) {
                        $scope.indicator.answer = { subAnswers: response };
                        minAndMax($scope.indicator.answer.subAnswers)
                    }

                    function error(error) {
                      console.error(error);
                    }
                }

                function minAndMax(lines) {
                  var values = [];
                  for(var i=0; i<lines.length; i++) {
                      values.push({
                          name: lines[i].office,
                          value: $filter('sum')(lines[i])
                      });
                  }

                  $scope.max = values.reduce(function(prev, current) {
                    return (prev.value > current.value) ? prev : current
                  });

                  $scope.min = values.reduce(function(prev, current) {
                    return (prev.value < current.value) ? prev : current
                  });
                }
            }
        });