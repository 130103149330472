angular.module('main')
    .directive('detalleFormulas', function() {
        return {
            scope: {
                specificSummary: '=',
                type: '=',
            },
            restrict: 'E',
            replace: true,
            templateUrl: 'detalle-formulas.tpl.html',
            controller: controller,
            controllerAs: 'vm',
            bindToController: true
        };

        function controller() {
            var vm = this;
            

        }
    });