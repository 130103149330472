angular.module('main')
    .directive('cardEquipment', function() {
        return {
            scope: {
                id: '=',
                title: '=',
                resumeEquipment: '=',
                typeTransporte: '=',
                idMonth: '=',
                type: '=',
                subType: '=',
                gotoAnchor: '=',
                onClick: '&'
            },
            restrict: 'E',
            replace: true,
            templateUrl: 'card-equipment.tpl.html',
            controller: controller,
            controllerAs: 'vm',
            bindToController: true
        };

        function controller($uibModal,companyCertificationService) {
            var vm = this;
            vm.model= {};
            vm.save= save;
            loadFilterResume();
          
            vm.enfocar = function(value) {
                vm.algunInputEnfocado = value;
            };
         
            
              function loadFilterResume() { 
                vm.resumeFilterEquipment = vm.resumeEquipment[vm.type];
                if (typeof vm.subType !== 'undefined' ) {
                    var subEquipment = [];
                    if (typeof vm.typeTransporte !== 'undefined' ) {
                        for (var index = 0; index < vm.resumeFilterEquipment.length; index++) {                  
                              if (vm.resumeFilterEquipment[index].answer != null) {                                
                                    if (vm.resumeFilterEquipment[index].answer.transport_type != null) {                                    
                                        if (vm.resumeFilterEquipment[index].answer.transport_type == vm.subType ) {                                           
                                            subEquipment.push(vm.resumeFilterEquipment[index]);
                                        }
                                    }
                              }   
                          }
                    }else{
                        for (var index = 0; index < vm.resumeFilterEquipment.length; index++) {
                            if (vm.resumeFilterEquipment[index].emission_type == vm.subType) {
                              if (vm.resumeFilterEquipment[index].answer != null) {
                                  subEquipment.push(vm.resumeFilterEquipment[index]);
                              }                              
                            }                              
                          }
                    }
                 
                  
                 vm.resumeFilterEquipment = subEquipment; 
                }
              }


              function save() { 
              data=[];
                companyCertificationService.saveConsumoeEquipments(  
                    
                    data["equipments"] = [ vm.resumeFilterEquipment[vm.resumeFilterEquipment.indexOf(vm.model)] ]
                
                
                ).then(success, error);
                function success(response) {
                   console.log(response);
                }

                function error(error) {
                    console.log(error);
                }
              }
        }
    });