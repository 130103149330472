angular.module('main')
        .directive('indicatorSchemeBuilder', function () {
            return {
                scope: {
                    certification: '=',
                    onSave: '='
                },
                restrict: 'E',
                templateUrl: 'indicator_scheme_builder.tpl.html',
                controller: controller
            };

            function controller($scope, indicatorService, containerService, certificationService, $stateParams) {
                $scope.schemes = [];
                $scope.indicators = [];
                loadContainers();
                $scope.loadIndicators = loadIndicators;
                $scope.containerChange = containerChange;
                $scope.remove = remove;
                $scope.add = add;
                
                function loadIndicators(searchText) {
                    indicatorService.searchByText(searchText)
                            .then(success)
                            .catch(error);

                    function success(response) {
                        $scope.indicators = response;
                    }

                    function error(error) {
                        console.log(error);
                    }
                }

                function loadContainers() {
                    containerService.findAll()
                            .then(success)
                            .catch(error);

                    function success(response) {
                        $scope.containers = response;
                    }

                    function error(error) {
                        console.log(error);
                    }
                }
                
                function containerChange(container) {
                    containerService.findSubContainers(container.id)// $scope.certification.id
                            .then(success)
                            .catch(error);

                    function success(response) {
                        if (response.length > 0) {
                            container.subContainers = response;
                            return;
                        }

                        $scope.container = container;
                        findIndicatorSchemes(container.id);
                    }

                    function error(error) {
                        console.log(error);
                    }
                }

                function remove(index) {
                    var id = $scope.schemes[index].id;
                    if(typeof id !== 'undefined') {
                        certificationService.removeIndicatorScheme($stateParams.id, id);
                    }
                    $scope.schemes.splice(index, 1);
                    
                }
                
                function findIndicatorSchemes(container) {
                    certificationService.findIndicatorSchemes($stateParams.id, container)
                            .then(success)
                            .catch(error);

                    function success(response) {
                        $scope.schemes = response;
                    }

                    function error(error) {
                        console.log(error);
                    }
                }
                
                function add(indicator) {
                    $scope.schemes.push({
                        certification: $scope.certification,
                        container: $scope.container,
                        indicator: {
                            id: indicator.id,
                            code: indicator.code,
                            name: indicator.name,
                            help: indicator.help
                        }})
                }
            }
        });