angular.module('main')
        .directive('longTextIndicator', function () {
            return {
                scope: {
                    indicator: '=',
                    container: '=',
                    onClick: '&'
                },
                transclude: true,
                replace: true,
                restrict: 'E',
                templateUrl: 'long-text-indicator.tpl.html',
                controller: controller
            };

            function controller($scope, $sce, $rootScope) {
                var inputText = $scope.indicator.help;
                if(typeof inputText === 'string' || inputText instanceof String){
                    $scope.indicator.help = $sce.trustAsHtml($scope.indicator.help);  
                }
                $scope.uploadProperties = {
                    type: 'evidence',
                    accept: '*/*',
                    preview: false,
                    multiple: true
                };

                if (typeof $scope.indicator.answer === 'undefined') {
                    $scope.indicator.answer = { evidences: [], enabledByUser: true };
                }

                $rootScope.$broadcast('elastic:adjust');

                updateCompletedYearlyIndicators();

                function updateCompletedYearlyIndicators() {
                    if (!$scope.indicator.requiredQuestion || !$scope.container) {
                        return;
                    }

                    if (!$scope.indicator.answer.enabledByUser) {
                        return;
                    }

                    $scope.container.requiredYearlyIndicators++;

                    if ($scope.indicator.answer.value) {
                        $scope.container.completedYearlyIndicators += 1;
                    }
                }
            }
        });