(function () {
    'use strict';

    angular
        .module('main')
        .controller('LoginController', LoginController);

    function LoginController(userService, $state) {
        var vm = this;
        vm.login = login;
        vm.cleanError = cleanError;
        
        function login(username, password) {
            userService.login(username, password).then(success, error);

            function success() {
                $state.go('home');
            }

            function error(response) {
                vm.error = response.data.error;
            }
        }
        
        function cleanError() {
            vm.error = null;
        }
    }

})();