angular.module('main')
        .directive('officeForm', function () {
            return {
                scope: {
                    model: '=',
                    countries: '=',
                    currencies: '='
                },
                restrict: 'E',
                transclude: true,
                replace: true,
                templateUrl: 'office-form.tpl.html',
                controller: controller
            };
            
            function controller() {
            }
        });