(function () {
    'use strict';

    angular
            .module('main')
            .service('indicatorService', indicatorService);

    function indicatorService($q, $http) {

        return {
            searchByText: searchByText,
            searchByCertificationAndContainer: searchByCertificationAndContainer,
            setSelectedIndicators: setSelectedIndicators,
            getSelectedIndicators: getSelectedIndicators,
            toggleShowTotalByEvidence : toggleShowTotalByEvidence

        };

        function setSelectedIndicators(indicators)
        {
            this.selectedIndicators = indicators;
        }

        function getSelectedIndicators(){
            return this.selectedIndicators;
        }

        this.selectedIndicators = [];

        function searchByText(text) {
            var deferred = $q.defer();

            $http.get('/api/indicators?search=' + text, {
                headers: {skipAuthorization: true}
            }).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        /**
         * Search a Indicator by Certification and Container
         * @todo: add cache usin deferred.resolve(data)
         * @param certificationId
         * @param containerId
         * @param companyId
         * @returns {jQuery.promise|promise|Promise|Promise<any>|d}
         */
        function searchByCertificationAndContainer(certificationId, containerId, companyId)
        {
            var deferred = $q.defer();


            $http.get("/api/" + certificationId + "/" + companyId + "/" + containerId + "/indicators").then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
        
        function toggleShowTotalByEvidence(id) {
            var deferred = $q.defer();
            var url = '/api/indicators/toggleShowTotals/' + id;
            $http.put(url).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();