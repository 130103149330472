(function () {
    'use strict';

    angular
      .module('main')
      .config(config);

    function config($stateProvider) {
        $stateProvider
        .state('offices', {
            parent: 'main',
            url: '/offices',
            templateUrl: 'office.tpl.html',
            controller: 'OfficeController', 
            controllerAs: 'vm'
        });
    }
})();