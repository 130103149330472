angular.module('main')
        .directive('loader', function () {
            return {
                scope: {},
                restrict: 'E',
                templateUrl: 'loader.tpl.html',
                controller: controller,
                controllerAs: 'vm',
                bindToController: true
            };

            function controller($rootScope) {
                var vm = this;
                vm.showLoader = false;

                $rootScope.$on('loader:start', loaderStart);
                $rootScope.$on('loader:finish', loaderFinish);

                function loaderStart() {
                    vm.showLoader = true;
                }

                function loaderFinish() {
                    vm.showLoader = false;
                }
            }
        });