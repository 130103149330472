(function () {
    'use strict';
    angular
            .module('main')
            .controller('HomeController', HomeController);

    function HomeController(company,user) {
        var vm = this;
        vm.company = company;
        vm.user = user;
    }

})();