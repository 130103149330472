(function () {
    'use strict';

    angular
        .module('main')
        .controller('GriController', GriController);

    function GriController($scope) {
        this.searchEnabled = false;
        $scope.itemArray = [
	        {id: 1, name: 'first'},
	        {id: 2, name: 'second'},
	        {id: 3, name: 'third'},
	        {id: 4, name: 'fourth'},
	        {id: 5, name: 'fifth'},
	    ];

    }

})();