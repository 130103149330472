(function () {
    'use strict';

    angular
            .module('main')
            .factory('LoaderInterceptor', OAuthInterceptor); 

    function OAuthInterceptor($rootScope, $q) {

        var count = 0;
        
        return {
            request: request,
            response: response,
            responseError: responseError
        };

        function request(config) {
            if (config.headers.skipLoader) {
                delete config.headers.skipLoader;
                return config;
            }

            if (++count === 1) {
                $rootScope.$broadcast('loader:start');
            }

            return config || $q.when(config);
        }

        function response(response) {
            if (count > 0) {
                --count;
            }

            if (count === 0) {
                $rootScope.$broadcast('loader:finish');
            }

            return response || $q.when(response);
        }

        function responseError(response) {
            if (count > 0) {
                --count;
            }

            if (count === 0) {
                $rootScope.$broadcast('loader:finish');
            }

            return $q.reject(response);
        }
    }
})();