(function () {
    'use strict';
    angular
        .module('main')
        .controller('OfficeConfigController', OfficeConfigController);

    function OfficeConfigController(officeService, $uibModal, $stateParams) {
        var vm = this;
        vm.newProductionLine = newProductionLine;
        vm.saveProductionLines = saveProductionLines;
        vm.saveCollaborators = saveCollaborators;
        vm.yearChange = yearChange;
        vm.showProductionLineConfirmation = showProductionLineConfirmation;
        vm.toggleUpdateNextMonths = toggleUpdateNextMonths;
        vm.toggleTooltipText = toggleTooltipText;
        vm.monthValueChange = monthValueChange;
        vm.init = init;

        function init() {
            vm.office = {id: $stateParams.id};
            vm.years = [];

            if ($stateParams.year) {
                vm.year =  $stateParams.year;
                yearChange();
            }

            for (var i = 0; i < 11; i++) {
                vm.years.push((new Date().getFullYear()+2) - i);
            }

        }
        
        function yearChange () {
            loadProductionLines();
            loadCollaborators();
            loadConfigurations();
        }

        function loadConfigurations() {
            officeService.findAllOfficeConfigurations(vm.office, vm.year)
                .then(success)
                .catch(error);

            function success(response) {
                vm.officeConfigurations = response;
            }

            function error(error) {
                console.log(error);
            }
        }

        function newProductionLine() {
            var modal = $uibModal.open({
                animation: true,
                component: 'newProductionLine',
                resolve: {
                }
            });

            modal.result.then(function (response) {
                saveProductionLine(response);
            });
        }

        function saveProductionLine(productionLine) {
            productionLine.year = vm.year;
            if (!productionLine.isPunctualValue) productionLine.isPunctualValue = false;
            else setPunctualProductionLines(productionLine, productionLine.punctualValue);

            officeService.saveProductionLine(vm.office, productionLine)
                    .then(success)
                    .catch(error);

            function success(response) {
                productionLine.id = response.id;
                vm.productionLines.push(productionLine);
            }

            function error(error) {
                console.log(error);
            }
        }

        function setPunctualProductionLines(productionLine, punctualValue) {
            productionLine.january = 
            productionLine.february = 
            productionLine.march =
            productionLine.april =
            productionLine.may =
            productionLine.june =
            productionLine.july =
            productionLine.august =
            productionLine.september =
            productionLine.october =
            productionLine.november =
            productionLine.december = punctualValue;
        }

        function monthValueChange(productionLine, month) {
            if (productionLine.autocompleteNextMonths) {
                var months = [
                    "january",
                    "february",
                    "march",
                    "april",
                    "may",
                    "june",
                    "july",
                    "august",
                    "september",
                    "october",
                    "november",
                    "december",
                ];
                
                var nextMonthPosition = months.indexOf(month) + 1;

                for (var pos = nextMonthPosition; pos < months.length; pos++) {
                    var currentMonth = months[pos];
                    productionLine[currentMonth] = productionLine[month];
                }
            }
        }

        function saveProductionLines() {
            save(0);

            function save(i) {
                if (i >= vm.productionLines.length) {
                    return;
                }

                officeService.saveProductionLine(vm.office, vm.productionLines[i])
                        .then(success)
                        .catch(error);

                function success() {
                    save(i + 1);
                }

                function error(error) {
                    console.log(error);
                }

            }
        }

        function loadProductionLines() {
            officeService.findProductionLines(vm.office, vm.year)
                    .then(success)
                    .catch(error);

            function success(response) {
                vm.productionLines = response;
            }

            function error(error) {
                console.log(error);
            }
        }

        function saveCollaborators() {
            save(0);

            function save(i) {
                if (i >= vm.collaborators.length) {
                    return;
                }

                officeService.saveCollaborator(vm.office, vm.collaborators[i])
                        .then(success)
                        .catch(error);

                function success() {
                    save(i + 1);
                }

                function error(error) {
                    console.log(error);
                }

            }
        }

        function loadCollaborators() {
            officeService.findCollaborators(vm.office, vm.year)
                    .then(success)
                    .catch(error);

            function success(response) {
                vm.collaborators = response;
            }

            function error(error) {
                console.log(error);
            }
        }

        function showProductionLineConfirmation(office, productionLine) {
            $uibModal.open({
                animation: true,
                component: 'deleteProductionLine',
                resolve: {
                    office: function () {
                        return office.id;
                    },
                    productionLine: function () {
                        return productionLine;
                    },
                    reloadList: function () {
                        return loadProductionLines;
                    }
                }
            });
        }

        function toggleUpdateNextMonths(option, type){
            $uibModal.open({
                animation: true,
                backdrop: 'static',
                component: 'toggleConfigurationOptionAutocomplete',
                resolve: {
                    office: function () {
                        return vm.office.id;
                    },
                    configurationOption: function () {
                        return option;
                    },
                    type: function () {
                        return type;
                    },
                    toggleTooltipText: function () {
                        return toggleTooltipText;
                    },
                    reloadList: function () {
                        return loadProductionLines;
                    }
                }
            });
        }

        function toggleTooltipText(flag){
            if(flag){
                return "Al apagarlo, si modifica el valor para un mes específico, los siguientes meses no se actualizarán con ese mismo valor.";
            }else{
                return "Al encender esta opción, si modifica el valor para un mes específico, los siguientes meses se actualizarán con ese mismo valor.";
            }
        }
    }

})();