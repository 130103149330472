(function () {
    'use strict';

    angular
        .module('main')
        .config(config);

    function config($stateProvider) {
        $stateProvider
            .state('officeEdit', {
                parent: 'main',
                url: '/offices/:id/edit',
                templateUrl: 'offices-edit.tpl.html',
                controller: 'OfficeEditController',
                controllerAs: 'vm'
            });
    }
})();