angular.module('main')
        .directive('certificationBody', function () {     
            return {
                scope: {
                    id: '=',
                    parentContainer: '=',
                    afterSave: '='
                },
                restrict: 'E',
                templateUrl: 'certification-body.tpl.html',
                controller: controller,
                controllerAs: 'vm',
                bindToController: true
            };
              
            function controller($state,companyCertificationService, answerService,  $uibModal) {
                var vm = this;
                vm.loadIndicators = loadIndicators;
                vm.save = save;
                vm.m = 'monthly';
                vm.y = 'yearly';
                vm.estado = true;
                vm.parentContainer.hasMonthlyIndicators = false;
                vm.parentContainer.hasYearlyIndicators = false;
               vm.cambiaEstado = cambiaEstado;
               vm.open_modal = open_modal;
               vm.optionWindow = optionWindow;
               vm.container = [];
               vm.openModalHelper = openModalHelper;
               vm.close = close;
           
               const header = document.querySelector('#header-list');   
               const inicio = document.querySelector('#li-init');  
                inicio.className = "";    
               const menuItems = document.querySelectorAll('.uib-tab.nav-item');              
               for (var i = 0; i < menuItems.length; i++) {
                    header.append(menuItems[i]);
                }  
               
                
                function close() {
                  $state.reload();
                }

                function loadIndicators(container) {
              
                    container.totalMonthlyIndicators = 0;
                    container.monthlyIndicators = [];
                    container.yearlyIndicators = [];
                    companyCertificationService.loadIndicators(vm.id, container.id).then(success, error);
  
                    function success(response) {
                   
                        filterIndicators(container, response);
                        if (vm.filter == 'monthly') {
                            vm.estado = false;
                        }else{
                            vm.estado = true;
                        }
                        optionWindow(vm.parentContainer.subContainers[0].name,vm.parentContainer.subContainers[0]);
                    }

                    function error(error) {
                        console.log(error);
                    }
                }

                function save(indicators) {
                    save(0);

                    function save(i) {
                        if (i >= indicators.length) {
                            if(typeof vm.afterSave !== 'undefined') {
                                vm.afterSave();
                            }
                            return;
                        }
                        
                        if(typeof indicators[i].answer === 'undefined') {
                            save(++i);
                            return;
                        }
                        
                        answerService.save(indicators[i])
                                .then(success)
                                .catch(error);

                        function success() {
                            save(++i);
                        }

                        function error(error) {
                            console.log(error);
                        }

                    }
                }

                function filterIndicators(container, indicators) {
                    var expr = new RegExp(/MONTHLY|SUMMARY/);

                    container.monthlyIndicators = indicators.filter(monthlyIndicators);
                    container.requiredMonthlyIndicators = 0;
                    container.completedMonthlyIndicators = 0;
                    if (container.monthlyIndicators.length > 0) {
                        vm.estado = false;
                        vm.parentContainer.hasMonthlyIndicators = true;
                        vm.filter = vm.filter ? vm.filter : vm.m;
                    }

                    container.yearlyIndicators = indicators.filter(yearlyIndicators);
                    container.requiredYearlyIndicators = 0;
                    container.completedYearlyIndicators = 0;
                    if (container.yearlyIndicators.length > 0) {
                        vm.parentContainer.hasYearlyIndicators = true;
                        vm.filter = vm.filter ? vm.filter : vm.y;
                    }
                    function monthlyIndicators(element) {
                        if(expr.test(element.type)) {
                            return element;
                        }
                    }

                    function yearlyIndicators(element) {
                        if(!expr.test(element.type)) {
                            return element;
                        }
                    }
                }
                function cambiaEstado(valor) { 
                  vm.estado = valor;
                  };

                  function open_modal() {
                    var indicators = vm.container.yearlyIndicators;
                    if (vm.filter === vm.m) {
                        indicators = vm.container.monthlyIndicators
                    }
                  var indicator = indicators[0];
                  var modal =  $uibModal.open({
                        animation: true,
                        component: 'helper',
                        windowClass: 'helper-modal',
                        size: 'lg',
                        resolve: {
                            indicator: function() {
                                return indicator;
                            },
                            indicators: function() {
                                return indicators;
                            }
                            
                        }
                    });
                }

                function optionWindow(option,container) {
                    vm.optionParent = option;
                    vm.container = container;
                }

                function openModalHelper(indicator) {
                    var modal =  $uibModal.open({
                          animation: true,
                          component: 'helper',
                          windowClass: 'helper-modal',
                          size: 'lg',
                          resolve: {
                           indicator : indicator
                          }
                      });
                  }
            }
        });
