(function () {
    'use strict';

    angular
            .module('main')
            .controller('NewCompanyController', NewCompanyController);

    function NewCompanyController(howDidYouFindUsOptionService, companyService, industryService, countryService) {
        var vm = this;
        vm.step = 0;
        vm.field = 0;
        vm.model = {howDidYouFindUsOptions: []};
        vm.save = save;
        vm.uploadProperties = {
            type: 'company-logo',
            accept: '*/*',
            preview: true,
            multiple: false
        };
        loadCiudades();
        loadIndustries();
        loadHowDidYouFindUsOptions();
        vm.model.mainCountry = {};
        vm.model.mainCountry = {id: 114, pais: 'Colombia',codigo : 'CO'};
        vm.numberOfEmployees = ['1-20','20-50','50-100','100-500','más de 500'];
        vm.modulosDavivienda = ['Calculadora de Carbono', 'Prácticas ambientales', 'Triple Impacto'];
        function save(form) {
            if (vm.field < 1) {
                vm.field++;
                return;
            }

            if (!form.$valid) {
                form.$setSubmitted();
                vm.field = getIndexOfFirstFieldWithError(form);
                if ( vm.field < 7) {
                    vm.field = 0;
                }else{
                    vm.field = 1;
                }
              
                return;
            }
                /*companyService.save(vm.model)
                    .then(success)
                    .catch(error); */

                    companyService.saveOfficeCheck(vm.model)
                    .then(success)
                    .catch(error);

            function success() {
                vm.step = 1;
            }

            function error(error) {
                console.log(error);
                vm.step = 2;
            }
        }

        function getIndexOfFirstFieldWithError(form) {
            var index = -1;
            for (var item in form) {
                if (item[0] === '$') {
                    continue;
                }
                
         
                index++;

                if (!form[item].$valid) {
                    return index;
                }
               
            }
            return index;
        }

        function loadCiudades() {
            countryService.findAllC('CO')
                    .then(success)
                    .catch(error);

            function success(response) {
                vm.ciudades = response;
            }

            function error(error) {
                console.log(error);
            }
        }

        function loadIndustries() {
            industryService.findAll()
                    .then(success)
                    .catch(error);

            function success(response) {
                vm.industries = response;
            }

            function error(error) {
                console.log(error);
            }
        }

        function loadHowDidYouFindUsOptions() {
            howDidYouFindUsOptionService.findAll()
                    .then(success)
                    .catch(error);

            function success(response) {
                vm.howDidYouFindUsOptions = response;
            }

            function error(error) {
                console.log(error);
            }
        }
    }

})();