(function () {
    'use strict';

    angular
            .module('main')
            .service('companyService', companyService);

    function companyService($q, $http, company,routeService) {

        return {
            save: save,
            update: update,
            saveCompanyCertification: saveCompanyCertification,
            findCompanyCertifications: findCompanyCertifications,
            saveOffice: saveOffice,
            saveOfficeCheck: saveOfficeCheck,
            findOffices: findOffices,
            saveUser: saveUser,
            findUsers: findUsers,
            findUser: findUser,
            deleteUser: deleteUser
        };

        function save(model) {
            var deferred = $q.defer();

            $http.post('/api/companies', model, {
                headers: {skipAuthorization: true}
            }).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function update(model) {
            var deferred = $q.defer();

            $http.put('/api/companies/' + company.id, model).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function saveCompanyCertification(model) {
            var deferred = $q.defer();

            $http.post('/api/companies/' + company.id + '/company-certifications', model).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function findCompanyCertifications() {
            var deferred = $q.defer();

            $http.get('/api/companies/' + company.id + '/company-certifications').then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function saveOffice(office) {
            var deferred = $q.defer();

            $http.post('/api/companies/' + company.id + '/offices', office).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
        function saveOfficeCheck(office) {
            var deferred = $q.defer();
            console.log(office);
            $http.post(routeService.routeLaravel()+'/api/offices/save/check', office).then(success, error);

            function success(response) {
                console.log(response);
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function findOffices() {
            var deferred = $q.defer();

            $http.get('/api/companies/' + company.id + '/offices').then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
        
        function saveProductionLine(productionLine) {
            var deferred = $q.defer();

            $http.post('/api/companies/' + company.id + '/production-lines', productionLine).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function findProductionLines() {
            var deferred = $q.defer();

            $http.get('/api/companies/' + company.id + '/production-lines').then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
        
        function saveUser(user) {
            var deferred = $q.defer();

            $http.post('/api/companies/' + company.id + '/users', user).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
        
        function findUsers() {
            var deferred = $q.defer();

            $http.get('/api/companies/' + company.id + '/users').then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
        
        function findUser() {
            var deferred = $q.defer();

            $http.get('/api/companies/' + company.id + '/me').then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
        
        function deleteUser(companyUser) {
            var deferred = $q.defer();

            $http.delete('/api/companies/'+company.id+'/users/'+companyUser.id).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
    }
})();