angular.module('main')
    .directive('helper', function() {
        return {
            scope: {
                resolve: '<',
                close: '&',
                dismiss: '&'
            },
            restrict: 'E',
            templateUrl: 'helper.tpl.html',
            controller: controller
        };


        function controller($scope,helperService,$sce,$uibModal,user,routeService) {

            $scope.isCollapsed = false;
            $scope.option = 1;
            $scope.option_rcs = 1;
            $scope.option_retro = 1;
            $scope.option_ask = 0;
            $scope.urlSce = urlSce;
            $scope.urlSceDocument = urlSceDocument;
            $scope.openModalPdf = openModalPdf;
            $scope.saveRadio = saveRadio;
            $scope.saveImprovement = saveImprovement;
            $scope.saveFunctionality = saveFunctionality;
            $scope.existsDocument = false;
            $scope.existsVideo = false;
            $scope.existsDicc = false;
            $scope.routeService = routeService.routeLaravel();
            $scope.oneAtATime = true;
            $scope.documentStatus = documentStatus;
            $scope.showIframe = false;
            $scope.oneAtATime = true;
            $scope.loadResources = loadResources;
            $scope.istrustAsHtml = convertHtml;
            $scope.isHTML = isHTML;

    /*        if ( isHTML( $scope.resolve.indicator.question)) {
            $scope.resolve.indicator.question = $sce.trustAsHtml($scope.resolve.indicator.question);
           } */
           
               const indexChat = document.querySelector('#hubspot-conversations-inline-iframe'); 
               var clon = indexChat.cloneNode("hubspot-conversations-inline-iframeout");  
               const helperChat = document.querySelector('#hubspot-conversations-inline-parent-helper');              
      
               helperChat.append(clon);
               helperChat.style.display = 'block';
                
                function close() {
                  $state.reload();
                }
            

            loadResources();
            loadFeedbacks(user);
            function loadResources() {
                helperService.resourcesAll($scope.resolve.indicator.id )
                        .then(success)
                        .catch(error);

                function success(response) {
                    $scope.resources = [];
                    $scope.resources = response.data;

                    $scope.resources.forEach(function(response) {
                         if (response.type == 'diccionario' ) {
                            $scope.existsDicc = true;
                        }
                        if (response.type == 'documento' ) {
                            $scope.existsDocument = true;
                        }
                        if (response.type == 'video' ) {
                            $scope.existsVideo = true;
                        }
                    });
                  
                }

                function error(error) {
                    console.log(error);
                }
            }
            function loadFeedbacks(user) {
                helperService.feedbackAll(user.email)
                        .then(success)
                        .catch(error);

                function success(response) {
                    $scope.feedbacks = [];
                    $scope.functionalities = [];
                    for (var index = 0; index < response.data.length; index++) {
                       if ('star' == response.data[index].type) {
                        $scope.feedbacks.push(response.data[index]);
                       }else if('option' == response.data[index].type){
                        $scope.functionalities.push(response.data[index]);
                       }  
                    }  
                    statusCheck();               
                }

                function error(error) {
                    console.log(error);
                }
            }

            function urlSce(url) {
               nuevaUrl = $sce.trustAsResourceUrl(url);

               return nuevaUrl;
            }

            function urlSceDocument(url) {
                nuevaUrl = $sce.trustAsResourceUrl( 'https://davivienda-susty.nyc3.cdn.digitaloceanspaces.com/'+url);
                return nuevaUrl;
             }

            function openModalPdf() {
                var modal =  $uibModal.open({
                      animation: true,
                      component: 'pdf-viewport',
                      windowClass: 'pdf-viewport-modal',
                      size: 'lg',
                  });
            }
            function saveRadio(id) {   
                const element = document.getElementsByName("rate-"+id);
                valor = 0 ;
                for (var index = 0; index < element.length; index++) {
                    if (element[index].checked) {
                        valor = element[index].value;
                    }
                }

                helperService.savefeedback(user.email,id,valor)
                        .then(success)
                        .catch(error);
                function success(response) {
                    console.log(response);
                }

                function error(error) {
                    console.log(error);
                }

            }

            function saveImprovement() {   
                const element = document.getElementById("mejoraFeedback");
                if (element.value != '') {
                    helperService.saveImprovement(element.value, user.email)
                    .then(success)
                    .catch(error);
                    function success(response) {
                        element.value = '';
                    }
                    function error(error) {
                        console.log(error);
                        element.value = '';
                    } 
                }
                                       
            }

            function statusCheck() {   
              
                for (var index = 0; index <  $scope.functionalities.length; index++) {
                    const element = document.getElementsByName($scope.functionalities[index].id);
                    element.checked = $scope.functionalities[index].response.star_count;
                }
                                       
            }

            function saveFunctionality(id) {   
                const element = document.getElementById("checkbox--"+id);
                valor = 0;
                if (element.checked) {
                    valor = 1;
                }
                
                helperService.savefeedback(user.email,id,valor)
                        .then(success)
                        .catch(error);
                function success(response) {
                    console.log(response);
                }

                function error(error) {
                    console.log(error);
                }
                                       
            }

            function documentStatus(params,id) {
                $scope.showIframe = params;
                const element = document.getElementById("iframe-"+id);
                element.style.visibility = "hidden";
                if (params) {
                    element.style.visibility = "visible";
                }
               
                
            }

            function isHTML(question) {
                console.log(question);
                var isHTML = /<[a-z][\s\S]*>/i.test(question);
               
                return isHTML;
            }
            function convertHtml(question) {
              console.log(question);
            var    questionQ = $sce.trustAsHtml(question);

                return questionQ;
            }
              
        }
    });