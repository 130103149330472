angular.module('main')
        .filter('percentage', function ($filter) {
            return percentage;

            function percentage(container, filter) {
                if (!filter) {
                    return '';
                }

                var value = 1;
                /*if (filter === 'monthly' && container.requiredMonthlyIndicators > 0) {
                    console.log('TOTAL');
                    console.log(container.completedMonthlyIndicators);
                    // console.log(container.requiredMonthlyIndicators);
                    console.log(container.totalMonthlyIndicators);
                    console.log('TOTAL');
                    value  = container.completedMonthlyIndicators / container.requiredMonthlyIndicators
                    // console.log(value);
                    // console.log(container);
                }

                if (filter === 'yearly' && container.requiredYearlyIndicators > 0) {
                    value  = container.completedYearlyIndicators / container.requiredYearlyIndicators
                }

                if (filter === 'monthly') {
                    container.monthlyIndicatorsProgres = value;
                }

                if (filter === 'yearly') {
                    container.yearlyIndicatorsProgres = value;
                }*/

                if (filter === 'monthly') {
                    value = container.monthlyProgress;
                }

                if (filter === 'yearly') {
                    value = container.annualProgress;
                }
                return $filter('number')(value, 2) + '%';
                
            }
        });
