angular.module('main')
        .directive('formIndicator', function () {
            return {
                scope: {
                    indicator: '=',
                    container: '=',
                    onClick: '&',
                    companyCertification: '='
                },
                transclude: true,
                replace: true,
                restrict: 'E',
                templateUrl: 'form-indicator.tpl.html',
                controller: controller
            };

            function controller($scope, $sce, $uibModal, answerService, companyCertificationService) {
                $scope.pageInfo = { page: 0, limit: 10 };
                var inputText = $scope.indicator.help;
                if(typeof inputText === 'string' || inputText instanceof String){
                    $scope.indicator.help = $sce.trustAsHtml($scope.indicator.help);  
                }
                $scope.openModal = openModal;
                $scope.remove = remove;
                $scope.loadAnswers = loadAnswers;
                $scope.updateEnabledByUser = updateEnabledByUser;

               

            
                if (typeof $scope.indicator.answers === 'undefined') {
                    loadAnswers();
                }else{
                    if ($scope.indicator.answers.length == 0 ||  $scope.indicator.answers.length >= 1 ) {
                        loadAnswers();
                    }
                }

                

              

                function openModal(answer) {
                    var modal = $uibModal.open({
                        animation: true,
                        component: 'formIndicatorRecord',
                        resolve: {
                            indicators: function () {
                                return angular.copy($scope.indicator.subIndicators);
                            },
                            parent: answer
                        }
                    });

                    modal.result.then(function (indicators) {
                        save(indicators, answer);
                    });
                }

                function save(indicators, answer) {
                    var isNewAnswer = false;
                    if (typeof answer === 'undefined') {
                        answer = {};
                        var isNewAnswer = true;
                    }

                    answer.subAnswers = [];
                    saveIndicatorAnswer(0);

                    function saveIndicatorAnswer(i) {
                        if (i >= indicators.length) {
                            saveFormAnswer(answer);
                            return;
                        }

                        answerService.save(indicators[i])
                                .then(success)
                                .catch(error);

                        function success(response) {
                            answer.subAnswers.push({id: response.id});
                            saveIndicatorAnswer(++i);
                        }

                        function error(error) {
                            console.log(error);
                        }
                    }

                    function saveFormAnswer(answer) {
                        answerService.save({
                            id: $scope.indicator.id,
                            type: $scope.indicator.type,
                            answer: answer
                        })
                                .then(success)
                                .catch(error);

                        function success(response) {
                            if (!response.subAnswers || !response.subAnswers.length) {
                                return;
                            }

                            if (isNewAnswer) {
                                $scope.pageInfo.totalCount++;
                                $scope.indicator.answers.push(answer);
                            }

                            answer.id = response.id;
                            answer.summary = response.summary;
                            answer.subAnswers = response.subAnswers;
                        }

                        function error(error) {
                            console.log(error);
                        }

                    }
                }

                function remove(item) {
                    answerService.remove(item.id)
                        .then(success)
                        .catch(error);

                    function success(response) {
                        var index = $scope.indicator.answers.indexOf(item);
                        $scope.indicator.answers.splice(index, 1);
                        $scope.pageInfo.totalCount--;
                    }

                    function error(error) {
                        console.log(error);
                    }
                }

                function loadAnswers() {
                    function success(data) {
                        if (data.page === 1) {
                            $scope.indicator.answers = data.entities;
                            $scope.answer = {
                                id: data.id,
                                enabledByUser: data.enabledByUser
                            };
                            updateCompletedYearlyIndicators();
                        } else {
                            Array.prototype.push.apply($scope.indicator.answers, data.entities);
                        }

                        $scope.pageInfo.page = data.page;
                        $scope.pageInfo.totalCount = data.totalCount;
                        $scope.pageInfo.limit = data.limit;
                        $scope.pageInfo.isLoading = false;                        
                    }
    
                    function error() {
                        $scope.pageInfo.isLoading = false;
                    }

                    if ($scope.pageInfo.isLoading) {
                        return;
                    }
                    
                    if ($scope.pageInfo.page === 1 && $scope.pageInfo.limit === 10) {
                        $scope.pageInfo.limit = 50;
                    } else {
                        ++$scope.pageInfo.page;
                    }

                    $scope.pageInfo.isLoading = true;
                    companyCertificationService.loadIndicatorAnswers($scope.companyCertification, $scope.indicator.id, $scope.pageInfo)
                        .then(success)
                        .catch(error)
                    ;
                }

                function updateEnabledByUser() {
                    save([], $scope.answer);
                }

                function updateCompletedYearlyIndicators() {
                    if (!$scope.indicator.requiredQuestion) {
                        return;
                    }

                    if (!$scope.answer.enabledByUser) {
                        return;
                    }

                    $scope.container.requiredYearlyIndicators++;

                    if ($scope.indicator.answers.length > 0) {
                        $scope.container.completedYearlyIndicators += 1;
                    }
                }
            }
        });