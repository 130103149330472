(function () {
    'use strict';

    angular
        .module('main')
        .controller('ConfigurationController', ConfigurationController);

    function ConfigurationController($scope) {
    	this.searchEnabled = false;
        $scope.itemArray = [
	        {id: 1, name: 'first'},
	        {id: 2, name: 'second'},
	        {id: 3, name: 'third'},
	        {id: 4, name: 'fourth'},
	        {id: 5, name: 'fifth'},
	    ];

	    $scope.branchArray = [
	        {id: 'branch1', name: '1'},
	        {id: 'branch2', name: '2'},
	        {id: 'branch3', name: '3'},
	        {id: 'branch4', name: '4'},
	        {id: 'branch5', name: '5'},
	    ];

	    $scope.inventoryArray = [
	        {id: 'inventory1', name: 'Global Reporting Initiative'},
	        {id: 'inventory2', name: 'Bandera azul ecológica'},
	    ];
    }

    angular.module('main').controller('ModalInventoryCtrl', function ($uibModal, $document) {
	  var $ctrl = this;
	  
	  $ctrl.animationsEnabled = true;

	  $ctrl.open = function (size, parentSelector) {
	    var parentElem = parentSelector ? 
	      angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
	    var modalInstance = $uibModal.open({
	      animation: $ctrl.animationsEnabled,
	      ariaLabelledBy: 'modal-title',
	      ariaDescribedBy: 'modal-body',
	      templateUrl: 'inventory-modal.html',
	      controller: 'ModalInstanceCtrl',
	      controllerAs: '$ctrl',
	      size: size,
	      appendTo: parentElem
	    });

	    
	  };

	});

	angular.module('main').controller('ModalInstanceCtrl', function ($uibModalInstance) {
	  var $ctrl = this;
	  
	  $ctrl.cancel = function () {
	    $uibModalInstance.dismiss('cancel');
	  };
	});

})();

