angular.module('main')
    .directive('emissionAerial', function() {
        return {
            scope: {
                indicator: '=',
                id: '=',
                openModalEvidences: '=',
                openModalEvidencesZip: '=',
                month: '='
            },
            restrict: 'E',
            replace: true,
            templateUrl: 'emission-aerial.tpl.html',
            controller: controller,
            controllerAs: 'vm',
            bindToController: true
        };

        function controller($uibModal,companyCertificationService,companyCertification) {
            var vm = this;
            vm.save = save;
            vm.updateEquipmentFilter = updateEquipmentFilter;
            vm.addEmissionDirect = addEmissionDirect;
            vm.specificSummaryf = specificSummary;
            vm.showConfirmation = showConfirmation;
            vm.showEquiomentList = showEquiomentList;
            vm.selectedMonth = vm.month[0];
            vm.totalPassengerNumber = 0;
            vm.emissionActivities = [];
            vm.summary = true;
            vm.setSummary = function() {
                vm.summary =  !vm.summary;
            };
              
            if (typeof vm.indicator.answers === 'undefined') {
                loadAnswers();
                
                loadEquipmentFilter();
            }else{
                loadAnswers();
                loadEquipmentFilter(); 
            }
            function loadAnswers() {
                companyCertificationService.loadIndicatorAnswersCalculator(vm.id, vm.indicator.id,'direct-emission',1,companyCertification.year)
                .then(success)
                .catch(error);
                function success(response) {
                  vm.indicator.answers = response.data;
                  console.log(response.data);
                  loadAirports(); 
                  loadEmissionActivities();
                  emissionSummary();
                }

                function error(error) {
                    console.log(error);
                }  
            }


            function loadEquipmentFilter() {
                companyCertificationService.loadEquipmentFilter(vm.id,'calcu_013',1)
                .then(success)
                .catch(error);
                function success(response) {
                  vm.equipments = response.data;
                }

                function error(error) {
                    console.log(error);
                }  
            }

            function save() {
               
                data = { 
                   "indicator_id": vm.indicator.id ,
                   "emission_direct_type": 'aereos',
                   "emission": 'significant',
                   "company_certification_id": vm.id,
                   "answers": vm.indicator.answers};
                companyCertificationService.saveIndicatorAnswersCalculator( data ,'direct-emission')
                .then(success)
                .catch(error);
                function success(response) {
                    loadAnswers();
                }

                function error(error) {
                    console.log(error);
                }               
            }

            function addEmissionDirect() {

                data = { 
                    "indicator_id": vm.indicator.id ,
                    "emission_direct_type": 'aereos',
                    "emission": 'significant',
                    "company_certification_id": vm.id};

                companyCertificationService.addResource( data,'direct-emission' )
                .then(success)
                .catch(error);
                function success(response) {
                    loadAnswers();
                    loadEquipmentFilter(); 
                }    
                function error(error) {
                    console.log(error);
                } 
              }

              function specificSummary() {
                vm.specificSummary = [];

                const volatil = [];

                for (var index = 0; index < vm.indicator.answers.length; index++) {
                    if (typeof vm.indicator.answers[index].equipment !== 'undefined') {
                        if (typeof vm.indicator.answers[index].equipment.emission_activity !== 'undefined') {
                            if (vm.indicator.answers[index].equipment.emission_activity.id == vm.emissionActivitiesSelect.id) {
                                volatil.push(vm.indicator.answers[index]);
                            }
                        }
                    }    
                }

                var equipos = [];
                var total =0;

                var c02 = 0;
                var ch4 = 0;
                var n20 = 0;

                vm.specificSummary['potencial_c02'] = 1 ;
                vm.specificSummary['potencial_ch4'] = 21;
                vm.specificSummary['potencial_n20'] = 310;

                
               

                for (var index = 0; index < volatil.length; index++) {

        
                    equipos.push(volatil[index].equipment.name); 

                    var totalEquipoConsumo =  totalConsumo(volatil[index].month_emission);                            
                     total += totalEquipoConsumo;

                     vm.specificSummary['factor_c02'] = volatil[index].equipment.emission_activity.emissionFactor.co2 ;
                     vm.specificSummary['factor_ch4'] = volatil[index].equipment.emission_activity.emissionFactor.ch4;
                     vm.specificSummary['factor_n20'] = volatil[index].equipment.emission_activity.emissionFactor.n2o;
                     c02 += (totalEquipoConsumo * volatil[index].equipment.emission_activity.emissionFactor.co2 * vm.specificSummary['potencial_c02']);
                     ch4 += (totalEquipoConsumo * volatil[index].equipment.emission_activity.emissionFactor.ch4 *  vm.specificSummary['potencial_ch4']);
                     n20 +=(totalEquipoConsumo * volatil[index].equipment.emission_activity.emissionFactor.n2o *  vm.specificSummary['potencial_n20'] );

               }

               vm.specificSummary['equipos'] = equipos ;
               vm.specificSummary['totalConsumo'] = total;
               vm.emissionSummary['totalPassengerNumber'] =  vm.totalPassengerNumber || 0;
               vm.specificSummary['c02'] = c02.toFixed(3);
               vm.specificSummary['ch4'] = ch4.toFixed(3);
               vm.specificSummary['n20'] = n20.toFixed(3);
               vm.specificSummary['totalc02e'] =  ((n20+ ch4 +c02) / 1000).toFixed(3);
               
              }
           
              function emissionSummary() {
                vm.emissionSummary = [];

                const volatil = [];
              
                var total =0;
                var equipos ='';
                var c02 = 0;
                var ch4 = 0;
                var n20 = 0;
                for (var index = 0; index < vm.indicator.answers.length; index++) {
                    if (typeof vm.indicator.answers[index].equipment !== 'undefined') {
                        if (typeof vm.indicator.answers[index].equipment.name !== 'undefined') {
                          
                            equipos += vm.indicator.answers[index].equipment.name+' ,'; 
                            var totalEquipoConsumo =  totalConsumo( vm.indicator.answers[index].month_emission);
         
                             total += totalEquipoConsumo;
                             c02 += (totalEquipoConsumo * vm.indicator.answers[index].equipment.emission_activity.emissionFactor.co2 * 1);
                             ch4 += (totalEquipoConsumo * vm.indicator.answers[index].equipment.emission_activity.emissionFactor.ch4 * 21);
                             n20 +=(totalEquipoConsumo *  vm.indicator.answers[index].equipment.emission_activity.emissionFactor.n2o* 301); 
                        }
                     }
                }

                vm.emissionSummary['c02'] = c02.toFixed(3) ;
                vm.emissionSummary['ch4'] = ch4.toFixed(3) ;
                vm.emissionSummary['n20'] = n20.toFixed(3) ;
                vm.emissionSummary['totalKM'] = total ;
               vm.emissionSummary['equipos'] = equipos ;
               vm.emissionSummary['totalPassengerNumber'] =  vm.totalPassengerNumber ;
               vm.emissionSummary['totalSummary'] = ((n20+ ch4 +c02) / 1000).toFixed(3);

              }
            
              function totalConsumo(emission) {
                var total = 0;
                vm.totalPassengerNumber = 0;
                total += typeof emission.january.consumo === 'undefined' ? 0 : parseInt(  emission.january.consumo );
                total += typeof emission.february.consumo === 'undefined' ? 0 :parseInt( emission.february.consumo);
                total += typeof emission.march.consumo === 'undefined' ? 0 :parseInt( emission.march.consumo);
                total += typeof emission.april.consumo === 'undefined' ? 0 : parseInt(emission.april.consumo);
                total += typeof emission.may.consumo === 'undefined' ? 0 :parseInt( emission.may.consumo);
                total += typeof emission.june.consumo === 'undefined' ? 0 :parseInt( emission.june.consumo);
                total += typeof emission.july.consumo === 'undefined' ? 0 : parseInt(emission.july.consumo);
                total += typeof emission.august.consumo === 'undefined' ? 0 :parseInt( emission.august.consumo);
                total += typeof emission.september.consumo === 'undefined' ? 0 : parseInt(emission.september.consumo);
                total += typeof emission.october.consumo === 'undefined' ? 0 :parseInt( emission.october.consumo);
                total += typeof emission.november.consumo === 'undefined' ? 0 : parseInt(emission.november.consumo);
                total += typeof emission.december.consumo === 'undefined' ? 0 :parseInt( emission.december.consumo);

                vm.totalPassengerNumber += typeof emission.january.passenger_number === 'undefined' ? 0 : parseInt(  emission.january.passenger_number );
                vm.totalPassengerNumber += typeof emission.february.passenger_number === 'undefined' ? 0 :parseInt( emission.february.passenger_number);
                vm.totalPassengerNumber += typeof emission.march.passenger_number === 'undefined' ? 0 :parseInt( emission.march.passenger_number);
                vm.totalPassengerNumber += typeof emission.april.passenger_number === 'undefined' ? 0 : parseInt(emission.april.passenger_number);
                vm.totalPassengerNumber += typeof emission.may.passenger_number === 'undefined' ? 0 :parseInt( emission.may.passenger_number);
                vm.totalPassengerNumber += typeof emission.june.passenger_number === 'undefined' ? 0 :parseInt( emission.june.passenger_number);
                vm.totalPassengerNumber += typeof emission.july.passenger_number === 'undefined' ? 0 : parseInt(emission.july.passenger_number);
                vm.totalPassengerNumber += typeof emission.august.passenger_number === 'undefined' ? 0 :parseInt( emission.august.passenger_number);
                vm.totalPassengerNumber += typeof emission.september.passenger_number === 'undefined' ? 0 : parseInt(emission.september.passenger_number);
                vm.totalPassengerNumber += typeof emission.october.passenger_number === 'undefined' ? 0 :parseInt( emission.october.passenger_number);
                vm.totalPassengerNumber += typeof emission.november.passenger_number === 'undefined' ? 0 : parseInt(emission.november.passenger_number);
                vm.totalPassengerNumber += typeof emission.december.passenger_number === 'undefined' ? 0 :parseInt( emission.december.passenger_number);


                return total;
              }
              function loadAirports() {
                companyCertificationService.loadAirports()
                .then(success)
                .catch(error);
                function success(response) {
                  vm.indicator.loadAirports = response.data;
                 
                }
                function error(error) {
                    console.log(error);
                }  
            }
              function loadEmissionActivities() {
                for (var index = 0; index < vm.indicator.answers.length; index++) {
                    console.log(vm.indicator.answers[index].equipment);
                    if (typeof vm.indicator.answers[index].equipment !== 'undefined') {
                        if (typeof vm.indicator.answers[index].equipment.emission_activity !== 'undefined') {
                            vm.emissionActivities.push(vm.indicator.answers[index].equipment.emission_activity);
                        }
                    }    
                }

                  vm.emissionActivitiesSelect =  vm.emissionActivities[0];
                  specificSummary();
              }
              function updateEquipmentFilter(oldE,newE,key) {
                if ( newE != null) {
                    for (var index = 0; index <   vm.equipments.length; index++) {
                        if(vm.equipments[index].id == oldE.id){
                         vm.equipments[index].notAnOption = 0;
                        }
                        if(vm.equipments[index].id == newE.id){
                         vm.equipments[index].notAnOption = 1;
                        }
                     }
                }else{
                    vm.indicator.answers[key].equipment =  oldE;
                }
              }

             function showConfirmation(answer, key) {
                $uibModal.open({
                    animation: true,
                    component: 'deleteAnswerEmission',
                    resolve: {
                        emissionAnswer: function () {
                            return answer;
                        },
                        key: function () {
                            return key;
                        },
                        loadAnswers: function () {
                            return loadAnswers;
                        }
                    }
                });
              }

              function showEquiomentList(answers, key) {
                $uibModal.open({
                    animation: true,
                    component: 'listEquipment',
                    resolve: {
                        equipment: function () {
                            return answers;
                        },
                        key: function () {
                            return key;
                        },
                    }
                });
              }


        }
    });