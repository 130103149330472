angular.module('main')
    .directive('emissionFarming', function() {
        return {
            scope: {
                indicator: '=',
                container: '=',
                id: '=',
                openModalEvidences: '=',
                openModalEvidencesZip: '=',
                month: '='
            },
            restrict: 'E',
            replace: true,
            templateUrl: 'emission-farming.tpl.html',
            controller: controller,
            controllerAs: 'vm',
            bindToController: true
        };

        function controller($uibModal,companyCertificationService,companyCertification,$q) {
            var vm = this;
            vm.save = save;
            vm.specificSummaryf = specificSummary;
            vm.addEmissionDirect = addEmissionDirect;
            vm.updateEquipmentFilter = updateEquipmentFilter;
            vm.selectedMonth = vm.month[0];
           vm.showConfirmation = showConfirmation;
            vm.showEquiomentList = showEquiomentList;
            vm.summary = true;
            vm.setSummary = function() {
                vm.summary =  !vm.summary;
            };
            if (typeof vm.indicator.answers === 'undefined') {
                loadAnswers();
                loadEquipmentFilter();
            }else{
                loadAnswers();
                loadEquipmentFilter(); 
            }

            vm.emissionActivities = [];
            function loadAnswers() {
                companyCertificationService.loadIndicatorAnswersCalculator(vm.id, vm.indicator.id,'direct-emission-agricultural',1,companyCertification.year)
                .then(success)
                .catch(error);
                function success(response) {
                  vm.indicator = response.data;
                  loadEmissionActivities();
                  emissionSummary();
                }

                function error(error) {
                    console.log(error);
                }  
            }


            function loadEquipmentFilter() {
                companyCertificationService.loadEquipmentFilter(vm.id,'calcu_002',5)
                .then(success)
                .catch(error);
                function success(response) {
                  vm.equipments = response.data;
                }

                function error(error) {
                    console.log(error);
                }  
            }

            function save() {
                var promises = [];
                 
                 for (var index = 0; index < vm.indicator.parent_parent.length; index++) {
                    
                    var option = 'agropecuaria';
                    data = { 
                      "indicator_id": vm.indicator.parent_parent[index].id ,
                      "emission_direct_type": option,
                      "emission": 'direct',
                      "company_certification_id": vm.id,
                      "answers": vm.indicator.parent_parent[index].answers};
                      var promise = companyCertificationService.saveIndicatorAnswersCalculator(data, 'direct-emission');
                      promises.push(promise);
                 
                }
                $q.all(promises).then(function(responses) {
                   
                    loadAnswers();
                }).catch(function(error) {
                    console.log("Error en alguna de las respuestas:", error);
                });
            }

            function addEmissionDirect(indicatorAdd) {

                var option = 'agropecuaria';
                
                data = { 
                    "indicator_id": indicatorAdd.id ,
                    "emission": 'direct',
                    "emission_direct_type": option,
                   "company_certification_id": vm.id};

                companyCertificationService.addResource( data,'direct-emission' )
                .then(success)
                .catch(error);
                function success(response) {
                    loadAnswers();
                    loadEquipmentFilter(); 
                }    
                function error(error) {
                    console.log(error);
                }    

            }

            function specificSummary() {
                vm.specificSummary = [];

                const volatil = [];
                for (var index = 0; index < vm.indicator.parent_parent.length; index++) {
                    for (var index2 = 0; index2 < vm.indicator.parent_parent[index].answers.length; index2++) {
                     
                        if ( vm.indicator.parent_parent[index].answers[index2].equipment !== null) {
                            if (typeof vm.indicator.parent_parent[index].answers[index2].equipment.emission_activity !== 'undefined') {
                                if (vm.indicator.parent_parent[index].answers[index2].equipment.emission_activity.id == vm.emissionActivitiesSelect.id) {
                                    volatil.push(vm.indicator.parent_parent[index].answers[index2]);
                                }
                            }
                        }
                    }    
                }
               
                var equipos = '';
                var total =0;
                var unidades ='';

                var c02 = 0;
                var ch4 = 0;
                var n20 = 0;
                vm.specificSummary['potencial_c02'] = 1 ;
                vm.specificSummary['potencial_ch4'] = 21;
                vm.specificSummary['potencial_n20'] = 310;
                for (var index = 0; index < volatil.length; index++) {
                    equipos += volatil[index].equipment.name+ ' ,'; 
                    
                    if (typeof  volatil[index].equipment.unit_of_measure.name !== 'undefined') {
                        unidades += volatil[index].equipment.unit_of_measure.name+ ' ,'; 
                    }
                    var totalEquipoConsumo =  totalConsumo(volatil[index].month_emission);

                     total += totalEquipoConsumo;
                     vm.specificSummary['factor_c02'] = volatil[index].equipment.emission_activity.emissionFactor.co2 ;
                     vm.specificSummary['factor_ch4'] = volatil[index].equipment.emission_activity.emissionFactor.ch4;
                     vm.specificSummary['factor_n20'] = volatil[index].equipment.emission_activity.emissionFactor.n2o;
                     c02 += (totalEquipoConsumo * volatil[index].equipment.emission_activity.emissionFactor.co2 * vm.specificSummary['potencial_c02']);
                     ch4 += (totalEquipoConsumo * volatil[index].equipment.emission_activity.emissionFactor.ch4 *  vm.specificSummary['potencial_ch4']);
                     n20 +=(totalEquipoConsumo * volatil[index].equipment.emission_activity.emissionFactor.n2o *  vm.specificSummary['potencial_n20'] );

               }

               vm.specificSummary['equipos'] = equipos ;
               vm.specificSummary['unidades'] = unidades;
               vm.specificSummary['totalConsumo'] = total ;
               vm.specificSummary['c02'] = c02.toFixed(3);
               vm.specificSummary['ch4'] = ch4.toFixed(3);
               vm.specificSummary['n20'] = n20.toFixed(3);
               vm.specificSummary['totalc02e'] = ((n20+ ch4 +c02) / 1000).toFixed(3);
            }


            function emissionSummary() {
                vm.emissionSummary = [];

                const volatil = [];
              
                var total =0;
                var unidades ='';

                var c02 = 0;
                var ch4 = 0;
                var n20 = 0;
                for (var index = 0; index < vm.indicator.parent_parent.length; index++) {
                    for (var index2 = 0; index2 < vm.indicator.parent_parent[index].answers.length; index2++) {
                        if ( vm.indicator.parent_parent[index].answers[index2].equipment !== null) {
                            if (typeof vm.indicator.parent_parent[index].answers[index2].equipment.unit_of_measure.name !== 'undefined') {
                                unidades = vm.indicator.parent_parent[index].answers[index2].equipment.unit_of_measure.name+ ' ,'; 
                             }
                             var totalEquipoConsumo =  totalConsumo(vm.indicator.parent_parent[index].answers[index2].month_emission);
                             total += totalEquipoConsumo;
                            
                             c02 += (totalEquipoConsumo * vm.indicator.parent_parent[index].answers[index2].equipment.emission_activity.emissionFactor.co2  * 1);
                             ch4 += (totalEquipoConsumo * vm.indicator.parent_parent[index].answers[index2].equipment.emission_activity.emissionFactor.ch4 * 21);
                             n20 +=(totalEquipoConsumo *  vm.indicator.parent_parent[index].answers[index2].equipment.emission_activity.emissionFactor.n2o * 301); 
                      
                         }
                    }                    
                }

               vm.emissionSummary['unidad'] = unidades ;
               vm.emissionSummary['totalConsumo'] = total ;
               vm.emissionSummary['totalSummary'] = (n20+ ch4 +c02) / 1000;

            }

              function totalConsumo(emission) {
                var total = 0;

                total += typeof emission.january.consumo === 'undefined' ? 0 : parseInt(  emission.january.consumo );
                total += typeof emission.february.consumo === 'undefined' ? 0 :parseInt( emission.february.consumo);
                total += typeof emission.march.consumo === 'undefined' ? 0 :parseInt( emission.march.consumo);
                total += typeof emission.april.consumo === 'undefined' ? 0 : parseInt(emission.april.consumo);
                total += typeof emission.may.consumo === 'undefined' ? 0 :parseInt( emission.may.consumo);
                total += typeof emission.june.consumo === 'undefined' ? 0 :parseInt( emission.june.consumo);
                total += typeof emission.july.consumo === 'undefined' ? 0 : parseInt(emission.july.consumo);
                total += typeof emission.august.consumo === 'undefined' ? 0 :parseInt( emission.august.consumo);
                total += typeof emission.september.consumo === 'undefined' ? 0 : parseInt(emission.september.consumo);
                total += typeof emission.october.consumo === 'undefined' ? 0 :parseInt( emission.october.consumo);
                total += typeof emission.november.consumo === 'undefined' ? 0 : parseInt(emission.november.consumo);
                total += typeof emission.december.consumo === 'undefined' ? 0 :parseInt( emission.december.consumo);
                return total;
              }

              function loadEmissionActivities() {
                for (var index = 0; index < vm.indicator.parent_parent.length; index++) {
                    for (var index2 = 0; index2 < vm.indicator.parent_parent[index].answers.length; index2++) {                     
                        if ( vm.indicator.parent_parent[index].answers[index2].equipment !== null) {
                            if (typeof vm.indicator.parent_parent[index].answers[index2].equipment.emission_activity !== 'undefined') {
                                vm.emissionActivities.push(vm.indicator.parent_parent[index].answers[index2].equipment.emission_activity);
                            }
                        }
                    }    
                } 

                console.log();
                  vm.emissionActivitiesSelect =  vm.emissionActivities[0];
                  specificSummary();
            }
            function updateEquipmentFilter(oldE,newE,key) {
                if ( newE != null) {
                    for (var index = 0; index <   vm.equipments.length; index++) {
                        if(vm.equipments[index].id == oldE.id){
                         vm.equipments[index].notAnOption = 0;
                        }
                        if(vm.equipments[index].id == newE.id){
                         vm.equipments[index].notAnOption = 1;
                        }
                     }
                }else{
                    vm.indicator.answers[key].equipment =  oldE;
                }
              }

             function showConfirmation(answer, key) {
                $uibModal.open({
                    animation: true,
                    component: 'deleteAnswerEmission',
                    resolve: {
                        emissionAnswer: function () {
                            return answer;
                        },
                        key: function () {
                            return key;
                        },
                        loadAnswers: function () {
                            return loadAnswers;
                        }
                    }
                });
              }

              function showEquiomentList(answers, key) {
                $uibModal.open({
                    animation: true,
                    component: 'listEquipment',
                    resolve: {
                        equipment: function () {
                            return answers;
                        },
                        key: function () {
                            return key;
                        },
                    }
                });
              }
        }
    });