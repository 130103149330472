angular.module('main')
        .directive('graph', function () {
            return {
                scope: {
                    indicator: '='
                },
                restrict: 'E',
                replace: true,
                templateUrl: 'graph.tpl.html',
                controller: controller,
                controllerAs: 'vm',
                bindToController: true
            };

            function controller(companyCertificationService, companyCertification, officeService) {
                var vm = this;
                vm.denominator = null;
                vm.denominators = [
                    {
                        id: 1,
                        name: 'Colaboradores'
                    },
                    {
                        id: 2,
                        name: 'Unidades de producción'
                    }
                ];
                vm.loadYears = loadYears;
                vm.denominatorChange = denominatorChange;
                vm.buildGraph = buildGraph;
                vm.buildGraphData = buildGraphData;
                vm.getTotal = getTotal;
                vm.getAverage = getAverage;
                vm.getMin = getMin;
                vm.getMax = getMax;
                vm.loadCollaborators = loadCollaborators;
                vm.loadProductionLines = loadProductionLines;

                function loadYears() {
                    companyCertificationService.findIndicatorHistory(companyCertification, vm.indicator)
                            .then(success)
                            .catch(error);

                    function success(response) {
                        vm.years = response;
                    }

                    function error(error) {
                        console.log(error);
                    }
                }
                
                function denominatorChange() {
                    vm.collaborator = null;
                    vm.productionLine = null;
                    if(!vm.denominator)
                        buildGraphData();
                }

                var months = [
                    'january',
                    'february',
                    'march',
                    'april',
                    'may',
                    'june',
                    'july',
                    'august',
                    'september',
                    'october',
                    'november',
                    'december'
                ];

                function buildGraph() {
                    buildGraphOptions();
                    buildGraphData();
                }

                function buildGraphOptions() {
                    vm.options = {
                        chart: {
                            type: 'lineChart',
                            margin: {
                                top: 45,
                                right: 45,
                                bottom: 45,
                                left: 55
                            },
                            height: 450,
                            useInteractiveGuideline: true,
                            color: d3.scale.category10().range(),
                            xAxis: {
                                showMaxMin: false,
                                tickFormat: function (d) {
                                    return months[d - 1];
                                },
                                tickPadding: 15
                            },
                            yAxis: {
                                showMaxMin: false,
                                axisLabel: typeof vm.indicator.answer.unitOfMeasure !== 'undefined' ? vm.indicator.answer.unitOfMeasure.name : '',
                                tickPadding: 15
                            }
                        }
                    };
                }

                function buildGraphData() {
                    if (!vm.year) {
                        vm.data = vm.years.map(mapYearSummary);
                    } else {
                        vm.data = vm.year.lines.map(mapYearDetail);
                    }

                    if (vm.denominator) {
                        applyDenominator();
                    }

                    function mapYearSummary(year) {
                        return formatLine(year.lines.reduce(groupLines, {}), year.year);

                        function groupLines(result, item) {
                            for (var i = 0; i < months.length; i++) {
                                result[months[i]] = (parseFloat(result[months[i]]) || 0) + parseFloat(item[months[i]] || 0);
                            }

                            return result;
                        }
                    }

                    function mapYearDetail(line) {
                        return formatLine(line, line.item.summary);
                    }

                    function formatLine(line, name) {
                        var values = [];
                        for (var i = 0; i < months.length; i++) {
                            values.push({
                                x: i + 1,
                                y: parseFloat(line[months[i]]) || 0
                            });
                        }

                        return {
                            values: values,
                            key: name
                        };
                    }

                    function applyDenominator() {
                        vm.data.map(mapData);

                        function mapData(item) {
                            var year = vm.year ? vm.year : vm.years.filter(filterYear)[0];
                            if(vm.denominator.id === 1 && vm.collaborator) {
                                var collaboratorFiltered = year.collaborators.filter(filterCollaborator)[0];
                                var collaboratorGroup = year.collaborators.reduce(groupCollaborators, {});
                                doIt(collaboratorFiltered, collaboratorGroup);
                            }
                            
                            if(vm.denominator.id === 1 && !vm.collaborator) {
                                var collaboratorGroup = year.collaborators.reduce(groupCollaborators, {});
                                doIt(collaboratorGroup, undefined);
                            }

                            if(vm.denominator.id === 2 && vm.productionLine) {
                                //console.log("producto seleccionado", vm.productionLine);
                                doIt(vm.productionLine, undefined);
                            }

                            if(vm.denominator.id === 2 && !vm.productionLine) {
                                //todo
                            }

                            function filterYear(year) {
                                if (year.year === item.key) {
                                    return year;
                                }
                            }
                            
                            function filterCollaborator(collaborator) {
                                if (collaborator.categoryName === vm.collaborator.categoryName) {
                                    return collaborator;
                                }
                            }
                            
                            function filterProductionLine(productionLine) {
                                if (productionLine.name === vm.productionLine.name) {
                                    return productionLine;
                                }
                            }

                            function groupCollaborators(result, item) {
                                //console.log(item, 'colaborator');

                                if(item.categoryName != 'Hombres' && item.categoryName != "Mujeres")
                                {
                                    for (var i = 0; i < months.length; i++) {
                                        //console.log("process month", months[i]);
                                        //console.log("buffer", result[months[i]]);
                                        //console.log("value",item[months[i]] );
                                        result[months[i]] = (parseFloat(result[months[i]]) || 0) + parseFloat(item[months[i]] || 0);
                                    }
                               }

                                //console.log('result', result);
                                return result;
                            }

                            function groupProductionLines(result, item) {
                                for (var i = 0; i < months.length; i++) {
                                    // console.log("process month", months[i]);
                                    // console.log("buffer", result[months[i]]);
                                    // console.log("value",item[months[i]] );
                                    result[months[i]] = (parseFloat(result[months[i]]) || 0) + parseFloat(item[months[i]] || 0);
                                }
;
                                return result;
                            }

                            function doIt(line, num) {

                                //console.log("doIt", line);
                                for (var i = 0; i < months.length; i++) {
                                    if(typeof line === 'undefined') {
                                        line = {};
                                    }

                                    var monthValue = (parseFloat(line[months[i]]) || undefined);
                                    //console.log("monthValue" + i, monthValue);
                                    if(monthValue === undefined){
                                        break;
                                    }
                                    if(num === undefined){
                                        var newVal = item.values[i].y / monthValue;
                                    } else {
                                        var monthValueGroup = (parseFloat(num[months[i]]) || 0);
                                        var newVal = (item.values[i].y / monthValueGroup) * monthValue;
                                    }
                                    newVal = isNaN(newVal) ? 0 : newVal;
                                    newVal = !isFinite(newVal) ? 0 : newVal;
                                    newVal = newVal % 1 !== 0 ? newVal.toFixed(2) : newVal;
                                    item.values[i].y = newVal;
                                }
                            }
                        }
                    }
                }

                function getTotal(data) {
                    return data.reduce(sum, 0);
                }

                function sum(total, item) {
                    return total += (parseFloat(item.y) || 0);
                }

                function getAverage(data) {
                    return data.reduce(sum, 0) / 12;
                }

                function getMin(data) {
                    return data.reduce(reduceMin);

                    function reduceMin(min, item) {
                        if (typeof min !== 'number') {
                            return min.y > item.y ? min.y : item.y;
                        }
                        return min < item.y ? min : item.y;
                    }
                }

                function getMax(data) {
                    return data.reduce(reduceMax);

                    function reduceMax(max, item) {
                        if (typeof max !== 'number') {
                            return max.y > item.y ? max.y : item.y;
                        }
                        return max > item.y ? max : item.y;
                    }
                }

                function loadCollaborators() {
                    var index = 0;
                    load();

                    function load() {
                        if (index >= vm.years.length) {
                            return buildGraphData();
                        }

                        officeService.findCollaborators({id: companyCertification.officeId}, vm.years[index].year)
                                .then(success)
                                .catch(error);
                    }

                    function success(response) {
                        vm.years[index].collaborators = response;
                        index++;
                        load();
                    }

                    function error(error) {
                        console.log(error);
                    }
                }

                function loadProductionLines() {
                    var index = 0;
                    load();

                    function load() {
                        if (index >= vm.years.length) {
                            return buildGraphData();
                        }

                        officeService.findProductionLines({id: companyCertification.officeId}, vm.years[index].year)
                                .then(success)
                                .catch(error);
                    }

                    function success(response) {
                        vm.years[index].productionLines = response;
                        index++;
                        load();
                    }

                    function error(error) {
                        console.log(error);
                    }
                }
            }
        });