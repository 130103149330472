angular.module('main')
    .filter('simbolNumberFormat', function ($filter) {
        return function (number, decimals) {
            var simbol = "";
            // Caracteres permitidos para numeros
            var regEx = new RegExp(/[^\d\-\.\,]/);
            // Limpiar el numero
            var cleanNumber = number.replace(regEx, '');
            // verificar si hay simbolo
            var matchSimbol = regEx.exec(number);
            if (matchSimbol) {
                // Guardar simbolo para uso despues de formato
                simbol = matchSimbol[0];
            } else {
                simbol = ''; // Simbolo en blanco
            }
            // cambiar cantidad de los decimales para los porcentajes
            if(simbol === '%')
                decimals = 2;

            // prueba de formato de simbolo
            var valorDecimales = $filter('number')(cleanNumber, decimals);
            // regresa el valor con el simbolo
            return valorDecimales + simbol;
        };
    });
