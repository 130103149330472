angular.module('main')
    .filter('comma2whitespace', [
        function() {
            return function(input) {
                // parseString = (input).toString();
                // splitString = parseString.split('.');
                // finalNumber = '';
                // console.log(splitString);
                // console.log(splitString.length);
                //
                // if(splitString.length == 2)
                // {
                //     console.log(splitString[0].length);
                //     if(splitString[0].length > 6)
                //     {
                //
                //     }
                //
                // }

                var ret=(input)?input.toString().trim().replace(/,/g, " ") : null;
                return ret;
            };
        }
    ]);