angular.module('main')
        .directive('backButton', function () {
            return {
                restrict: 'A',
                link: function (scope, element, attr, ngModelCtrl) {
                    element.bind('click', function () {
                        history.back();
                        scope.$apply();
                    });
                }
            };
        });