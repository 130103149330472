(function () {
    'use strict';

    angular
            .module('main')
            .controller('CertificationController', CertificationController);

    function CertificationController($anchorScroll,$location,$stateParams, companyCertification, companyCertificationService, containerService, $state) {
        var vm = this;
        vm.id = $stateParams.id;
        companyCertification.id = $stateParams.id;
        vm.loadContainers = loadContainers;
        vm.loadSubContainers = loadSubContainers;
        vm.afterSave = afterSave;
        vm.resumen = false;
        $location.hash('top--scroll');
        $anchorScroll();
        function loadContainers() {
            companyCertificationService.loadContainers($stateParams.id).then(success, error);

            function success(response) {
                vm.containers = response;
                vm.resumen = true;
            }

            function error(error) {
                console.log(error);
            }
        }
        
        function loadSubContainers(container) {
            if(typeof container.subContainers !== 'undefined') {
                return;
            }

            containerService.findSubContainers(container.id, $stateParams.id, false).then(success, error);

            function success(response) {
                container.subContainers = response;
            }

            function error(error) {
                console.log(error);
            }
        }
        
         function afterSave() {
            $state.go($state.current, {}, {reload: true});
        } 
    }

})();
