(function () {
    'use strict';

    angular
            .module('main')
            .service('companyCertificationService', companyCertificationService);

    function companyCertificationService($q, $http,routeService,companyCertification) {

        return {
            findOneById: findOneById,
            loadContainers: loadContainers,
            loadSetupContainers: loadSetupContainers,
            loadIndicators: loadIndicators,
            loadProgressFromOthersCertifications: loadProgressFromOthersCertifications,
            loadFormIndicators: loadFormIndicators,
            loadIndicatorAnswers: loadIndicatorAnswers,
            findSuggestedMaterialAspects: findSuggestedMaterialAspects,
            findIndicatorHistory: findIndicatorHistory,
            loadIndicatorSummary: loadIndicatorSummary,
            deleteCertification: deleteCertification,
            loadIndicatorAnswersCalculator: loadIndicatorAnswersCalculator,
            saveIndicatorAnswersCalculator: saveIndicatorAnswersCalculator,
            saveConsumoeEquipments: saveConsumoeEquipments,
            addResource: addResource,
            loadEquipmentFilter: loadEquipmentFilter,
            loadEmissionActivities: loadEmissionActivities,
            loadUnitOfMeasure: loadUnitOfMeasure,
            loadEmissionTotals: loadEmissionTotals,
            loadMeasurersFilter: loadMeasurersFilter,
            loadAirports: loadAirports,
            loadTotalsYears: loadTotalsYears,
            deleteEmission: deleteEmission,
            resumeEquipment: resumeEquipment,
            
        };

        function findOneById(id) {
            var deferred = $q.defer();

            $http.get('/api/company-certifications/' + id).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function loadContainers(id) {
            var deferred = $q.defer();

            $http.get('/api/company-certifications/' + id + '/containers').then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
        
        function loadSetupContainers(id) {
            var deferred = $q.defer();

            $http.get('/api/company-certifications/' + id + '/containers?type=setup').then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
        
        function loadIndicators(id, container) {
            var deferred = $q.defer();

            $http.get(
                '/api/company-certifications/' + id + '/containers/' + container + '/indicators',
                {
                    headers: { skipLoader: true }
                }
            ).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
        
        function loadProgressFromOthersCertifications(id) {
            var deferred = $q.defer();

            $http.get('/api/company-certifications/'+id+'/progress-from-others-certifications').then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
        
        function loadFormIndicators(id, forms) {
            if(typeof forms === 'undefined') {
                forms = 'all';
            }

            var deferred = $q.defer();

            $http.get('/api/company-certifications/'+id+'/indicators?type=form&forms='+forms).then(success, error);
            
            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function loadIndicatorAnswers(companyCertificationId, indicatorId, options) {
            var deferred = $q.defer();
            var params = '?';
            Object.keys(options || {}).forEach(function(key) {
                var delimiter = '';
                if (params.contains('=')) {
                    delimiter = '&&';
                }
                params+= delimiter + key + '=' + options[key];
            });

            $http.get(
                '/api/company-certifications/'+companyCertificationId+'/indicators/'+ indicatorId+'/answers'+params,
                {
                    headers: { skipLoader: true }
                }
            ).then(success, error);
            //here
            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
        
        function findSuggestedMaterialAspects(id) {
            var deferred = $q.defer();

            $http.get('/api/company-certifications/'+id+'/material-aspects').then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
        
        function findIndicatorHistory(companyCertification, indicator) {
            var deferred = $q.defer();

            $http
                .get('/api/company-certifications/'+companyCertification.id+'/indicators/'+indicator.id)
                .then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function loadIndicatorSummary(companyCertification, indicator) {
            var deferred = $q.defer();

            $http.get('/api/company-certifications/'+companyCertification+'/indicators/'+indicator+'/answers').then(success, error);

            function success(response) {
              deferred.resolve(response.data);
            }

            function error(error) {
              deferred.reject(error);
            }

            return deferred.promise;
        }

        function deleteCertification(companyCertification) {
            var deferred = $q.defer();

            $http.delete('/api/company-certifications/'+companyCertification).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }


        //apis  de la calculadora 
        function loadIndicatorAnswersCalculator(companyCertificationId, indicatorId,type,filterType,year) {
            var deferred = $q.defer();
            filterType = filterType || 1;
            $http.get(routeService.routeLaravel()+'/api/indicators-answers-calculator/'+type+'/'+companyCertificationId+'/'+indicatorId+'/'+filterType+'/'+year,
                {
                    headers: { skipLoader: true }
                }
            ).then(success, error);
            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function saveIndicatorAnswersCalculator(data,type) {
            var deferred = $q.defer();

            $http.post(routeService.routeLaravel()+'/api/indicators-answers-calculator/'+'save/'+type,data).then(success, error);
            function success(response) {
                deferred.resolve(response.data);
            }
            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
        function saveConsumoeEquipments(data) {
            var deferred = $q.defer();

            $http.post(routeService.routeLaravel()+'/api/equipments/save/consumo/',{"equipments":data}).then(success, error);
            function success(response) {
                deferred.resolve(response.data);
            }
            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
        function addResource(data,type) {
            var deferred = $q.defer();
            $http.post(routeService.routeLaravel()+'/api/indicators-answers-calculator/'+'add-resource/'+type,data).then(success, error);
            function success(response) {
                deferred.resolve(response.data);
            }
            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function loadEquipmentFilter(company_certification_id,code,type) {
            var deferred = $q.defer();

            $http.get(routeService.routeLaravel()+'/api/equipments/'+'filter/'+company_certification_id+'/'+code+'/'+type,
                {
                    headers: { skipLoader: true }
                }
            ).then(success, error);
            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function loadEmissionActivities() {
            var deferred = $q.defer();
            $http.get(routeService.routeLaravel()+'/api/emission-activity/'+'get/'+companyCertification.officeId,
                {
                    headers: { skipLoader: true }
                }
            ).then(success, error);
            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function loadUnitOfMeasure() {
            var deferred = $q.defer();
            $http.get(routeService.routeLaravel()+'/api/unit-of-measure/'+'get',
                {
                    headers: { skipLoader: true }
                }
            ).then(success, error);
            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function loadEmissionTotals(data,year) {
            var deferred = $q.defer();
            var data = JSON.stringify(data);
       
            $http.get(routeService.routeLaravel()+'/api/emission-total/answers-total/'+data+'/'+year, 
                {
                    headers: { skipLoader: true }
                }
            ).then(success, error);
            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function loadMeasurersFilter(emission,type,id,indicator_id) {
            var deferred = $q.defer();
            $http.get(routeService.routeLaravel()+'/api/measurers/'+'filter/'+emission+'/'+type+'/'+id+'/'+indicator_id,
                {
                    headers: { skipLoader: true }
                }
            ).then(success, error);
            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }


        function loadAirports() {
            var deferred = $q.defer();
            $http.get(routeService.routeLaravel()+'/api/airports/'+'get',
                {
                    headers: { skipLoader: true }
                }
            ).then(success, error);
            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }


        function loadTotalsYears(certification_id,officeId) {
            var deferred = $q.defer();       
            $http.get(routeService.routeLaravel()+'/api/emission-total/answers-total-years/'+certification_id+'/'+officeId, 
                {
                    headers: { skipLoader: true }
                }
            ).then(success, error);
            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function deleteEmission(id,type) {
            var deferred = $q.defer();       
            $http.delete(routeService.routeLaravel()+'/api/indicators-answers-calculator/destroy/'+type+'/'+id, 
                {
                    headers: { skipLoader: true }
                }
            ).then(success, error);
            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function resumeEquipment(codes,id) {
            var deferred = $q.defer();      
            var data = JSON.stringify(codes); 
            $http.get(routeService.routeLaravel()+'/api/indicators-answers-calculator/resume-equipment/'+data+'/'+id, 
                {
                    headers: { skipLoader: true }
                }
            ).then(success, error);
            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
        // fin de apis de la calculadora
        
    }
})();