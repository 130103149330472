(function () {
    'use strict';

    angular
      .module('main')
      .config(config);

    function config($stateProvider) {
        $stateProvider
        .state('gri', {
            parent: 'singlePage',
            url: '/gri',
            templateUrl: 'gri.tpl.html',
            controller: 'GriController', 
            controllerAs: 'vm'
        });
        $stateProvider.state('materiality', {
            parent: 'inner',
            url: '/materiality',
            templateUrl: 'materiality.tpl.html',
            controller: 'GriController', 
            controllerAs: 'vm'
        });
        $stateProvider.state('report', {
            parent: 'inner',
            url: '/report',
            templateUrl: 'report.tpl.html',
            controller: 'GriController', 
            controllerAs: 'vm'
        });
        $stateProvider.state('gri-progress', {
            parent: 'inner',
            url: '/gri-progress',
            templateUrl: 'gri-progress.tpl.html',
            controller: 'GriController', 
            controllerAs: 'vm'
        });
    }
})();