(function () {
    'use strict';
    angular
            .module('main')
            .controller('OfficeController', OfficeController);

    function OfficeController(companyService, company, $uibModal) {
        var vm = this;
        vm.init = init;
        
        function init() {
            vm.company = company;
            vm.remove = remove;
            loadOffices();
        }
        
        function remove(office) {
            var index = vm.offices.indexOf(office);
            vm.offices.splice(index, 1);
        }
        
        function loadOffices() {
            companyService.findOffices()
                    .then(success)
                    .catch(error);

            function success(response) {
                vm.offices = response;
            }

            function error(error) {
                console.log(error);
            }
        }

        vm.showConfirmation = showConfirmation;

        function showConfirmation(office, key) {
            // console.log(certification);
            $uibModal.open({
                animation: true,
                component: 'deleteSucursal',
                resolve: {
                    office: function () {
                        return office;
                    },
                    key: function () {
                        return key;
                    },
                    reloadList: function () {
                        return loadOffices;
                    }
                }
            });
        }
    }

})();