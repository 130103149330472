angular.module('main')
        .directive('user', function () {
            return {
                restrict: 'E',
                replace: true,
                templateUrl: 'user.tpl.html',
                controller: controller,
                controllerAs: 'vm',
                bindToController: true
            };

            function controller(company, companyService, $uibModal, indicatorService) {
                var vm = this;
                vm.company = company;
                vm.users = [];
                vm.groups = [
                    {key: 'Admnistrador General', name: 'COMPANY_ADMIN'},
                    {key: 'Admnistrador Oficina', name: 'OFFICE_ADMIN'},
                    {key: 'Gestor de Certificaciones', name: 'GESTOR_CERTIFICACIONES'}
                ];

                vm.model = getDefaultModel();
                vm.loadOffices = loadOffices;
                vm.loadUsers = loadUsers;
                vm.saveUser = saveUser;
                vm.removeUsers = removeUsers;
                vm.showModal = showUserPermissions;
                vm.validateExtraInformation = validateExtraInformation;
                vm.showChat = showChat;
                initPermissions();
                showChat(false);


                function loadOffices() {
                    companyService.findOffices().then(success, error);

                    function success(response) {
                        vm.offices = response;
                    }

                    function error(error) {
                        console.log(error);
                    }
                }

                function loadUsers() {
                    companyService.findUsers()
                            .then(success)
                            .catch(error);

                    function success(response) {
                        vm.users = response;
                    }

                    function error(error) {
                        console.log(error);
                    }
                }

                function showUserPermissions(item, model) {
                    vm.validateExtraInformation();
                    if(model.name == 'GESTOR_CERTIFICACIONES')
                    {
                        var modal = $uibModal.open({
                            animation: true,
                            windowClass:'certification-permissions-modal',
                            component: 'certificationPermissions',
                            resolve: {

                            }
                        });
                        modal.result.then(function (response) {
                            vm.selectedPermissions = response;
                            indicatorService.setSelectedIndicators(response);
                            vm.validateExtraInformation();
                        });
                    }
                    else
                    {
                        vm.model.offices = []; // Limpiar oficinas durante el cambio
                        initPermissions();
                    }
                }

                function validateExtraInformation() {
                    if(typeof vm.group === 'undefined') {
                        vm.extraFormValid = false;
                        return;
                    }

                    switch (vm.group.name) {
                        case 'GESTOR_CERTIFICACIONES':
                            vm.extraFormValid = vm.selectedPermissions.length > 0;
                            break;
                        case 'OFFICE_ADMIN':
                            vm.extraFormValid = (typeof vm.model.offices !== 'undefined') && (vm.model.offices.length > 0);
                            break;
                        default:
                            vm.extraFormValid = true;
                            break;
                    }

                };

                function saveUser(model, form) {
                    model.user.groups = [
                      { name: vm.group.name }
                    ];
                    model.permissions = vm.selectedPermissions.map(function (permission) {
                        permission.certification = {id: permission.certification};
                        permission.office = {id: permission.office};
                        permission.container = {id: permission.container};
                        permission.indicator = {id: permission.indicator};
                        return permission;
                    });

                    companyService.saveUser(model).then(success, error);

                    function success(response) {
                        if(response.error) {
                          vm.message = {
                            type: 'danger',
                            text: 'Ya existe un usuario con el correo ' + model.user.email
                          };
                          return;
                        }
                        model.id = response.id;
                        vm.users.push(model);
                        vm.model = getDefaultModel();
                        initPermissions();
                        vm.group = {};
                        vm.message = {
                            type: 'success',
                            text: 'su usuario se agregó correctamente'
                        };
                        form.$setPristine();
                    }

                    function error(error) {
                        vm.message = {
                            type: 'danger',
                            text: 'ocurrio un error al crear el usuario, por favor verifique los datos'
                        };
                        console.log(error);
                    }
                }

                function removeUsers(index) {
                    companyService.deleteUser(vm.users[index]);
                    vm.users.splice(index, 1);
                }

                function getDefaultModel() {
                    return {user: {groups: [], offices: []}, permissions: []};
                }

                function initPermissions() {
                    indicatorService.setSelectedIndicators(
                        vm.model.permissions
                    ); // Clean data
                    vm.selectedPermissions = vm.model.permissions;
                    validateExtraInformation();
                }
            }

            function showChat(show)
            {
                var bubbleChat = angular.element( document.getElementById('hubspot-messages-iframe-container'))[0];
                console.log(bubbleChat);
                if(typeof bubbleChat !== 'undefined'){
                    if(!show && (bubbleChat.style.cssText.indexOf('z-index') === -1)) {
                        bubbleChat.style.cssText =  bubbleChat.style.cssText + 'z-index: 1040;';
                    }
                    else if(show) {
                        bubbleChat.style.cssText =  bubbleChat.style.cssText.replace('z-index: 1040;', '');
                    }

                }
            }
        });