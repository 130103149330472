angular.module('main')
        .directive('openMenu', function ($uibModal) {
            return {
                restrict: 'A',
                link: link
            };

            function link(scope, element, attr, ngModelCtrl) {
                element.bind('click', openMenu);

                function openMenu() {
                    scope.$uibModalInstance.close();
                    $uibModal.open({
                        animation: true,
                        component: 'menu',
                        windowClass: 'right'
                    });
                }
            }
        });