angular.module('main')
        .directive('login', function () {
            return {
                restrict: 'E',
                replace: true,
                templateUrl: 'login.tpl.html',
                controller: controller,
                controllerAs: 'vm',
                bindToController: true,
                scope: {
                    resolve: '<',
                    close: '&',
                    dismiss: '&'
                },
            };
    
            function controller($state, userService, company) {
                var vm = this;
                vm.login = login;
                vm.cleanError = cleanError;
                vm.message = vm.resolve.message;
                
                function login(username, password) {
                    userService.login(username, password).then(success, error);

                    function success() {
                        vm.close();
                        if (!company.id) {
                            $state.go('home', {}, { reload: true });
                        }
                    }

                    function error(response) {
                        vm.error = response.data.error;
                    }
                }
                
                function cleanError() {
                    vm.error = null;
                }
            }
        });