(function () {
    'use strict';

    angular
      .module('main')
      .config(config);

    function config($httpProvider) {
        $httpProvider.interceptors.push('LoaderInterceptor');
    }

})();