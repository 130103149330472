(function () {
    'use strict';

    angular
        .module('main')
        .config(config);

    function config($stateProvider) {
        $stateProvider
            .state('inner', {
                abstract: true,
                views: {
                    '@': {
                        templateUrl: 'inner.tpl.html'
                    },
                    'header@inner': {
                        templateUrl: '../header-inner.tpl.html'
                    },
                    'footer@inner': {
                        templateUrl: '../footer.tpl.html'
                    }
                }
            });
    }
})();