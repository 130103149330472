angular.module('main')
        .filter('averageProperty', function () {
            const MONTHS = 12;
            return sum;

            function sum(array, property) {
                var total = 0;
                
                if(typeof array === 'undefined'){
                    return 0;
                }
                
                if (typeof array.length === 'undefined') {

                    return sumObject(array);
                }

                for (var i = 0; i < array.length; i++) {
                    if(typeof property !== 'undefined') {
                        total += sumByProperty(array[i], property);
                    } else {
                        total += (parseFloat(array[i]) || 0);
                    }
                }
                
                return total;
            }

            function sumByProperty(item, property) {
                if(typeof item[property] === 'undefined') {
                    return 0;
                }
                
                var val = (parseFloat(item[property]) || 0);
                return isNaN(val) ? 0 : val;
            }

            function sumObject(object) {
                var total = 0;
                for (var k in object) {
                    if (k !== 'year' && k !== 'id' && k !== 'indicator' && k !== 'certification' && k !== 'unit') {
                        var val = (parseFloat(object[k]) || 0);
                        if(isNaN(val)) {
                            continue;
                        }
                        total += val;
                    }
                }
                return (total/MONTHS);
            }
        });