angular.module('main')
        .directive('deleteEquipment', function () {
            return {
                scope: {
                    resolve: '<',
                    close: '&',
                    dismiss: '&'
                },
                restrict: 'E',
                templateUrl: 'delete-equipment.tpl.html',
                controller: controller
            };

            function controller($scope, companyCertificationService, $state) {
                init();

                function init() {
                    $scope.model = {};
                    $scope.emissionAnswer = $scope.resolve.emissionAnswer;
                    $scope.delete = deleteEquipmentEmission;
                    $scope.key = $scope.resolve.key;
                    
                }
                function deleteEquipmentEmission(id)
                {  
                    var  type = 'branch-office';
                    if (typeof  $scope.emissionAnswer.branch_office_id === 'undefined'){
                        type = 'equipment'
                    }
                    
                    if ( $scope.key != 'emission') {
                        var promise = companyCertificationService.deleteEmission(id,type);
                    }else{
                        var promise = companyCertificationService.deleteEmission(id,'emission');
                    }

                   
                    promise.then(function(data){
                        $scope.resolve.loadAnswers();
                        $scope.close();
                    }, function (error) {
                    });
                }
            }
        });