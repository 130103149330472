(function () {
    'use strict';

    angular
            .module('main')
            .service('industryService', industryService);

    function industryService($q, $http) {

        return {
            findAll: findAll,
            findSubIndustries: findSubIndustries
        };

        function findAll() {
            var deferred = $q.defer();

            $http.get('api/industries', {
                headers: {skipAuthorization: true}
            }).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function findSubIndustries(id) {
            var deferred = $q.defer();

            $http.get('/api/industries/' + id + '/sub-industries', {
                headers: {skipAuthorization: true}
            }).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
    }
})();