(function () {
    'use strict';

    angular
            .module('main')
            .service('officeService', officeService);

    function officeService($q, $http) {

        return {
            find: find,
            save: save,
            saveProductionLine: saveProductionLine,
            findProductionLines: findProductionLines,
            saveCollaborator: saveCollaborator,
            findCollaborators: findCollaborators,
            deleteSucursal: deleteSucursal,
            deleteProductionLine: deleteProductionLine,
            saveOfficeConfigurationOption: saveOfficeConfigurationOption,
            toggleOptionAutocompleteByType: toggleOptionAutocompleteByType,
            toggleConfigurationOptionAutocomplete: toggleConfigurationOptionAutocomplete,
            findAllOfficeConfigurations: findAllOfficeConfigurations,
        };
        
        function find(officeId) {
            var deferred = $q.defer();

            $http.get('/api/offices/' + officeId).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function save(office) {
            var deferred = $q.defer();

            $http.put('/api/offices/' + office.id, office).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function saveProductionLine(office, productionLine) {
            var deferred = $q.defer();

            $http.post('/api/offices/' + office.id + '/production-lines', productionLine).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function findProductionLines(office, year) {
            var deferred = $q.defer();

            $http.get('/api/offices/' + office.id + '/production-lines/'+year).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
        
        function saveCollaborator(office, collaborator) {
            var deferred = $q.defer();

            $http.post('/api/offices/' + office.id + '/collaborators', collaborator).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function findCollaborators(office, year) {
            var deferred = $q.defer();

            $http.get('/api/offices/' + office.id + '/collaborators/'+year).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function deleteSucursal(office) {
            var deferred = $q.defer();

            $http.delete("/api/offices/"+office).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function deleteProductionLine(officeId, id) {
            var deferred = $q.defer();

            $http.delete("/api/offices/"+officeId+"/production-lines/"+id).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function toggleOptionAutocompleteByType(routeOption, option) {
            var deferred = $q.defer();

            $http.post(
                '/api/' + routeOption + '/toggle-autocomplete', option
            )
            .then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function saveOfficeConfigurationOption(office, data) {
            var deferred = $q.defer();

            $http.post('/api/offices/' + office.id + '/save-configuration', data).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function findAllOfficeConfigurations(office, year) {
            var deferred = $q.defer();

            $http.get('/api/offices/' + office.id + '/configurations/' +  year).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function toggleConfigurationOptionAutocomplete(option) {
            var deferred = $q.defer();

            $http.post(
                '/api/office-configuration/toggle-autocomplete', option
            )
            .then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
    }
})();